/* Module: S */
//.tiles {
//  margin: 0 -5px 0.5em;
//  display: -webkit-box;
//  display: -moz-box;
//  display: -ms-flexbox;
//  display: -webkit-flex;
//  display: flex;
//  -webkit-flex-wrap: wrap;
//  flex-wrap: wrap;
//}
//
//.tile-item {
//  //margin: 0 5px 0.5em;
//  background-color: $medium-grey;
//  background-position: right 1em bottom 1em;
//  background-repeat: no-repeat;
//  border: 2px solid #ccc;
//  @include sq-border-radius(3px);
//  //width: calc(25% - 10px);
//  //padding: 16px 90px 16px 13px;
//  //position: relative;
//  //min-width: 222px;
//
//  .half-width & {
//    width: calc(50% - 10px);
//  }
//
//  &__header {
//    margin: 0 0 16px;
//    font-weight: 200;
//  }
//
//  a {
//    @extend .link-arrow;
//    font-weight: 200;
//  }
//
//  p {
//    margin-bottom: 1em;
//  }
//
//  &.icon-pliers {
//    background-image: url('{{file_dest}}/tile-icon-pliers.png');
//    background-size: 45px auto;
//  }
//
//  &.icon-pound {
//    background-image: url('{{file_dest}}/tile-icon-pound.png');
//    background-size: 50px auto;
//  }
//
//  &.icon-house {
//    background-image: url('{{file_dest}}/tile-icon-house.png');
//    background-size:68px auto;
//  }
//
//  &.important {
//    border-color: #f3aba1;
//    background-color: #fceae7;
//
//    &:before,
//    &:after {
//      position: absolute;
//      width: 0;
//      height: 0;
//      border-style: solid;
//      content: "";
//      right: 0;
//      bottom: 0;
//    }
//
//    &:before {
//      border-width: 0 0 75px 75px;
//      border-color: transparent transparent #de2e14 transparent;
//    }
//
//    &:after {
//      border-width: 0 0 50px 50px;
//      border-color: transparent transparent #e5624f transparent;
//    }
//  }
//}
//
//@media (max-width: 991px) {
//  .tile-item {
//    width: calc(50% - 10px);
//  }
//}
//
//@media (max-width: 767px) {
//  .tile-item {
//    width: 100%;
//    margin-bottom: 0;
//    border: none;
//    border-top: 2px solid #ccc;
//    border-radius: 0;
//
//    .half-width & {
//      width: 100%;
//    }
//  }
//}