/* Module: S */
.form {
  .intro__form {
    @extend .intro;
    font-weight: 300;
    font-size: 1.5em;
    .lowther & {
      font-weight: normal;
    }
  }
  &__break {
    border-top: 1px solid #ccc;
    padding-bottom: 20px;
  }
  &__body {
    background-color: #f8f8f8;
    border-bottom: 3px solid #e4e4e4;
    margin-bottom: 1em;
    padding: 20px;
    ul {
      padding-left: 0;
      list-style: none;
    }
    li {
      display: inline-block
    }
    fieldset {
      border: none;
      label {
        font-size: 1em;
        font-weight: 700;
        color: $font-grey;
      }
      legend {
        font-size: 1em;
        font-weight: 700;
        color: $font-grey;
        margin-bottom: 0;
      }
      .form__questions,
      .form__subquestions {
        display: none;
      }
      select, input[type=text], textarea, input[type=email] {
        width: 100%;
        @include sq-border-radius(0px);
        height: 40px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-size: 1.25em;
        color: #999999;
        padding-left: 20px;
        border: 1px solid #999999;
        margin-bottom: 30px;
        padding-right: 60px;
        //&:focus {
        //  outline: none;
        //  @include sq-box-shadow(0px 0px 5px $brand-primary);
        //}
      }
      select::-ms-expand {
        display: none;
      }
      select {
        cursor: pointer;
        background: #ffffff url("mysource_files/dropdown-grey.png") right center / 40px 40px no-repeat;
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        -webkit-linear-gradient(right, rgba(102, 102, 102, 1) 40px, rgba(255, 255, 255, 1) 40px);
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        -moz-linear-gradient(right, rgba(102, 102, 102, 1) 40px, rgba(255, 255, 255, 1) 40px);
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        linear-gradient(to left, rgba(102, 102, 102, 1) 40px, rgba(255, 255, 255, 1) 40px);
        &:hover, &:focus {
          background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
          background: url("mysource_files/dropdown.svg") right center no-repeat,
          -webkit-linear-gradient(right, rgba(226, 46, 20, 1) 40px, rgba(255, 255, 255, 1) 40px);
          background: url("mysource_files/dropdown.svg") right center no-repeat,
          -moz-linear-gradient(right, rgba(226, 46, 20, 1) 40px, rgba(255, 255, 255, 1) 40px);
          background: url("mysource_files/dropdown.svg") right center no-repeat,
          linear-gradient(to left, rgba(226, 46, 20, 1) 40px, rgba(255, 255, 255, 1) 40px);
          .gha-cube & {
            background: #ffffff url("mysource_files/gha-cube-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
          }
          .gha-wlhp & {
            background: #ffffff url("mysource_files/gha-wlhp-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
          }
          .dunedin & {
            background: #ffffff url("mysource_files/dunedin-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
          }
          .loretto & {
            background: #ffffff url("mysource_files/loretto-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, rgba(0, 169, 224, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, rgba(0, 169, 224, 1) 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, rgba(0, 169, 224, 1) 40px, rgba(255, 255, 255, 1) 40px);
          }
          .barony & {
            background: #ffffff url("mysource_files/barony-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, $barony-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, $barony-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, $barony-primary 40px, rgba(255, 255, 255, 1) 40px);
          }
          .yourplace & {
            background: #ffffff url("mysource_files/yourplace-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, $yourplace-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, $yourplace-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, $yourplace-primary 40px, rgba(255, 255, 255, 1) 40px);
          }
          .lowther & {
             background: #ffffff url("mysource_files/yourplace-dropdown.png") right center / 40px 40px no-repeat;
             background: url("mysource_files/dropdown.svg") right center no-repeat,
             -webkit-linear-gradient(right, $lowther-primary 40px, rgba(255, 255, 255, 1) 40px);
             background: url("mysource_files/dropdown.svg") right center no-repeat,
             -moz-linear-gradient(right, $lowther-primary 40px, rgba(255, 255, 255, 1) 40px);
             background: url("mysource_files/dropdown.svg") right center no-repeat,
             linear-gradient(to left, $lowther-primary 40px, rgba(255, 255, 255, 1) 40px);
           }
          .wheatley & {
            background: #ffffff url("mysource_files/wheatley-dropdown.png") right center / 40px 40px no-repeat;
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -webkit-linear-gradient(right, $wheatley-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            -moz-linear-gradient(right, $wheatley-primary 40px, rgba(255, 255, 255, 1) 40px);
            background: url("mysource_files/dropdown.svg") right center no-repeat,
            linear-gradient(to left, $wheatley-primary 40px, rgba(255, 255, 255, 1) 40px);
          }
        }
        &:focus::-ms-value {
          background-color: #ffffff;
          color: black;
        }
      }
      .form__dropdown {
        position: relative;
        &-box {
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 40px;
          background: #666666 url("mysource_files/dropdown.svg") 100% 50%/40px 40px no-repeat;
        }
        select:hover + .form__dropdown-box,
        select.is-selected + .form__dropdown-box {
          background: red url("mysource_files/dropdown.svg") 100% 50%/40px 40px no-repeat;
          transition: background-color 0.3s;
          .gha-cube & {
            background-color: $cube-main-color-red;
          }
          .gha-wlhp & {
            background-color: $wlhp-main-color-blue;
          }
          .dunedin & {
            background-color: $dunedin-main-color-green;
          }
          .loretto & {
            background-color: $loretto-main-color-darkblue;
          }
          .barony & {
            background-color: $barony-secondary;
          }
          .yourplace & {
            background-color: $yourplace-secondary;
          }
          .lowther & {
            background-color: $lowther-primary;
          }
          .wheatley & {
            background-color: $wheatley-primary;
          }
        }
      }
      input {
        background: $white;
      }
      input[type=radio] {
        position: relative;
        @include sq-border-radius(10px);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: $white;
        width: 20px;
        height: 20px;
        border: 1px solid #999999;
        vertical-align: -20%;
        box-sizing: border-box;
        display: inline-block;
        margin-top: 0;
        //&:focus {
        //  outline: none;
        //  @include sq-box-shadow(0px 0px 5px $brand-primary);
        //}
        & ~ label {
          margin-bottom: 30px;
          font-weight: 200;
          margin-right: 20px;
        }
        &:after {
          position: absolute;
          width: 10px;
          top: 4px;
          left: 4px;
          height: 10px;
          content: " ";
          background-color: transparent;
          @include sq-border-radius(5px);
        }
        &:checked:after {
          position: absolute;
          width: 10px;
          top: 4px;
          left: 4px;
          height: 10px;
          content: " ";
          background-color: $brand-primary;
          @include sq-border-radius(5px);

          .gha-cube & {
            background-color: $cube-main-color-green;
          }
          .gha-wlhp & {
            background-color: $wlhp-main-color-blue;
          }
          .dunedin & {
            background-color: $dunedin-main-color-blue;
          }
          .loretto & {
            background-color: $loretto-main-color-blue;
          }
          .barony & {
            background-color: $barony-primary;
          }
          .yourplace & {
            background-color: $yourplace-primary;
          }
          .lowther & {
            background-color: $lowther-primary;
          }
          .wheatley & {
            background-color: $wheatley-primary;
          }
        }
      }
      input::-ms-check {
        color: $brand-primary;
        .gha-cube & {
          color: $cube-main-color-green;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-blue;
        }
        .dunedin & {
          color: $dunedin-main-color-blue;
        }
        .loretto & {
          color: $loretto-main-color-blue;
        }
        .barony & {
          color: $barony-primary;
        }
        .yourplace & {
          color: $yourplace-primary;
        }
        .lowther & {
          color: $lowther-primary;
        }
        .wheatley & {
          color: $wheatley-primary;
        }
      }
      textarea {
        height: 200px;
        padding: 10px 20px;
      }
      .error {
        color: $brand-primary;

        .gha-cube & {
          color: $cube-main-color-green;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-blue;
        }
        .dunedin & {
          color: $dunedin-main-color-blue;
        }
        .loretto & {
          color: $loretto-main-color-blue;
        }
        .barony & {
          color: $barony-primary;
        }
        .yourplace & {
          color: $yourplace-primary;
        }
      }
      .error:required:invalid {
        border: 2px solid $black;
        &::-webkit-input-placeholder {
          color: $brand-primary;

          .gha-cube & {
            color: $cube-main-color-green;
          }
          .gha-wlhp & {
            color: $wlhp-main-color-blue;
          }
          .dunedin & {
            color: $dunedin-main-color-blue;
          }
          .loretto & {
            color: $loretto-main-color-blue;
          }
          .barony & {
            color: $barony-primary;
          }
          .yourplace & {
            color: $yourplace-primary;
          }
          .lowther & {
            color: $lowther-primary;
          }
          .wheatley & {
            color: $wheatley-primary;
          }
        }
      }
      .button__form {
        @extend .button;
        a {
          color: $white;
          font-weight: 200;
          padding: 0 15px;
        }
        &:focus {
          background-color: $brand-primary;

          .gha-cube & {
            background-color: $cube-main-color-red;
          }
          .gha-wlhp & {
            background-color: $wlhp-main-color-purple;
          }
          .dunedin & {
            background-color: $dunedin-main-color-green;
          }
          .loretto & {
            background-color: $loretto-main-color-darkblue;
          }
          .barony & {
            background-color: $barony-secondary;
          }
          .yourplace & {
            background-color: $yourplace-secondary;
          }
          .lowther & {
            background-color: $lowther-primary;
          }
          .wheatley & {
            background-color: $wheatley-primary;
          }
        }
      }
      p {
        margin: 18px;
      }
      .columnlist {
        ul {
          li {
            display: list-item;
            label {
              margin-bottom: 5px;
            }
            &:last-child {
              label {
                margin-bottom: 30px;
              }
            }
          }
        }
      }

      .sq-form-question-answer {
        label {
          font-weight: 400;
        }

        input[type="radio"].sq-form-field {
          margin-left: 0;
          & + label {
            margin-right: 20px;
          }
        }
      }
    }

    .sq-form-unattached {
      > .sq-form-question-text {
        margin: 0 2px;
        padding: 0 0.625em;
      }
    }
  }
}

.sq-form-required-field {
  display: inline-block;
}
