/* Module: S */
.accordion {
  background-color: #f8f8f8;
  border-bottom: 3px solid #e4e4e4;
  .wheatley & {
    border-bottom: 0px solid $wheatley-primary;
  }
  margin-bottom: 1em;
  padding-bottom: 0;
  .lowther & {
    border-bottom:0;
  }
  ul {
    margin-top: -10px;
    ul {
      padding-left: 11px;


      li:first-child {
        background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 2px / 6px;
        padding-left: 17px;
        font-weight: 200;

        .gha-cube & {
          background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
        }
        .gha-wlhp & {
          background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
        }
        .dunedin & {
          background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
        }
        .loretto & {
          background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
        }
        .barony & {
          background-image: url('{{file_dest}}/barony-bullet-arrow.svg');
        }
        .yourplace & {
          background-image: url('{{file_dest}}/yourplace-bullet-arrow.svg');
        }
        .lowther & {
          background-image: url('{{file_dest}}/lowther-bullet-arrow.svg');
        }
        .wheatley & {
          background-image: url('{{file_dest}}/wheatley-bullet-arrow.svg');
        }
      }
    }

    list-style: none;
    padding-left: 1px;

    li {
      font-size: pxToEm(16);
      line-height: pxToEm(24);
      font-weight: 200;
      &:last-child {
        margin-bottom: 1.5em;
      }

    }
  }

  h2 {
    background-color: #e4e4e4;
    color: $gray-darker;
    font-size: 1.125em;
    padding: 21px 55px 16px 20px;
    margin: 0;
    cursor: pointer;
    position: relative;
    line-height: 1.3;
    .lowther & {
      background:#444444;
      color:#fff;
    }
    .wheatley & {
      background-color: $wheatley-primary;
      color: white;
      font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 500;
    }

    .sq-open,
    .sq-close {
      min-width: 20px;
      position: absolute;
      right: 3%;
      top: 50%;
      margin-top: -10px;
    }

    .sq-close {
      background: url('mysource_files/expand.png') no-repeat 50% 50%;
      .lowther &, .wheatley & {
        background: url('mysource_files/expand-white.png') no-repeat 50% 50%;
      }
    }
    .sq-open {
      background: url('mysource_files/collapse.png') no-repeat 50% 50%;
      .lowther &, .wheatley & {
        background: url('mysource_files/collapse-white.png') no-repeat 50% 50%;
      }
    }
  }

  > .row {
    padding: 0 3%;
    .lowther & {
      padding: 5px 1% 0 1%;
      border-bottom: 3px solid #e4e4e4;
      margin: 0;
    }
  }

  h3 {
    font-size: 1.25em;
    margin: 1em 0;
    margin-bottom: 1.5em;
    font-weight: lighter;
    color: #222;
  }

  li {
    margin-bottom: 1em;

    > ul {
      margin-top: 1em;
    }
  }
  .side {
    p {

    }
  }
}

.lowther {
  .col-sm-3 {
    .accordion {
      > .row {
        padding: 30px 1% 0 1%;
      }
    }
  }
}
