/* Module: S */
.cookie {
  background: none repeat scroll 0 0 #990000;
  padding: 6px 20px 4px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  .gha-cube & {
    background: none repeat scroll 0 0 $cube-main-color-red;
  }
  .gha-wlhp & {
    background: none repeat scroll 0 0 $wlhp-main-color-purple;
  }
  .dunedin & {
    background: none repeat scroll 0 0 $dunedin-main-color-green;
  }
  .loretto & {
    background: none repeat scroll 0 0 $loretto-main-color-darkblue;
  }
  .barony & {
    background: none repeat scroll 0 0 $barony-secondary;
  }
  .yourplace & {
    background: none repeat scroll 0 0 $yourplace-secondary;
  }
  .lowther & {
    background: none repeat scroll 0 0 $lowther-primary;
    color: $black;
  }
  .wheatley & {
    background: none repeat scroll 0 0 $wheatley-primary;
  }

  span {
    line-height: 30px;
    padding-right: 40px;
    position: relative;
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: #fff;
    .lowther & {
      color: $black;
    }
    &:hover {
      text-decoration: underline;
      .gha-cube &, .gha-wlhp &, .wheatley & {
        color: $white;
      }
      .lowther & {
        color: $black;
      }
    }
  }

  .cookie_button {
    background: url(mysource_files/closeicon_white.png) 50% 50%/12px 12px no-repeat;
    background: url(mysource_files/closeicon_white.svg) 50% 50%/12px 12px no-repeat;
    display: block;
    width: 16px;
    height: 16px;
    border: none;
    position: absolute;
    top: 6px;
    right: 0px;
    .lowther & {
      background: url(mysource_files/closeicon_black.png) 50% 50%/12px 12px no-repeat;
      background: url(mysource_files/closeicon_black.svg) 50% 50%/12px 12px no-repeat;
    }
  }
}

.head-fix .cookie {
  display: none;
}



#wheatleySwitch {
  span {
    display: table-cell;
    padding-right: 10px;
    vertical-align: middle;
    p {
      margin: 0;
      padding-left: 20px;
    }
  }
  .onoffswitch {
    position: relative;
    width: 58px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 30px;
    padding: 0;
    line-height: 30px;
    border: 2px solid #999999;
    border-radius: 30px;
    background-color: #6E7071;
    transition: background-color 0.3s ease-in;
  }
  .onoffswitch-label:before {
    content: "";
    display: block;
    width: 30px;
    margin: 0px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    border: 2px solid #cfcfcf;
    border-radius: 30px;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #16c710;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: #cfcfcf;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
  }
}
