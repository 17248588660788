/* Gets bootstrap variables for media size */
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

/*Gets Font Awesome Full */
@import "../../bower_components/components-font-awesome/scss/fontawesome.scss";
@import "../../bower_components/components-font-awesome/scss/solid.scss";
@import "../../bower_components/components-font-awesome/scss/regular.scss";
@import "../../bower_components/components-font-awesome/scss/brands.scss";

// Base font
$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Custom
$black: #000 !default;
$white: #fff !default;

$font-grey: #7a7a7a;
//$font-grey: #727272;
$medium-grey: #efefef;
$dark-red: #9b0000;
$brand-primary: #e22e14; // GHA bright red
$brand-cta-yellow: #FFC72C;
$footer-muted: #777777;
$footer-bg-1: #DDDDDD;
$footer-bg-2: #EEEEEE;
$light-bg: #FCEEED;
$light-border: #ED7F74;

// gha Cube
$cube-main-color-green: #008675;
$cube-main-color-red: #a50034;
$cube-light-bg: #f8ebef;
$cube-light-border: #e3b2c1; //#e0a6b8;
$cube-light-responsive: #339e91;

// gha wlhp
$wlhp-main-color-blue: #004c97;
$wlhp-main-color-purple: #af1685;
$wlhp-light-bg: #f8ebef;
$wlhp-light-border: #e3aed4;
$wlhp-light-responsive: #1e5d9e;


// Dunedin
$dunedin-main-color-blue: #002f6c;
$dunedin-main-color-green: #205c40;
$dunedin-main-color-yellow: #ffc72c;
$dunedin-main-color-yellow-border: #ffe395;
$dunedin-light-bg: #EBF8F2;
$dunedin-light-border: #607c6f; //#87D5B1;
$dunedin-main-color-blue-lighter: #395F91;
$dunedin-main-color-blue-lightest: #6D8EB9;
$dunedin-light-responsive: #2D558A;

// Loretto
$loretto-main-color-blue: #00A9E0;
$loretto-main-color-darkblue: #007096; //
$loretto-light-bg: #edf9fd; //#EBF4F8;
$loretto-light-border: #7fb7ca; //#A7CCE0;
$loretto-main-color-blue-lighter: #c3dfe9; //#75B4C9;
$loretto-main-color-blue-lightest: #7fd4ef; //#BCD6DF;
$loretto-light-responsive: #2082a3; //#b2d4df; //#5FB0CB;

// Barony -> Dunfries and Galloway
$barony-primary: #006d7f;
$barony-secondary: #FFC72C;
$barony-light-border: #fbe396;
$barony-bg: #fefbef;
$barony-bg-blue: #fefbef;
$barony-bg-gray: #5A5D62;
$barony-bg-landingboxes: #fffbf0;
$barony-light-border-landingboxes: #ffeebf;
$barony-dark-blue: #177ba3;
$barony-light-blue: #cee6f1;
$barony-border: mix(white, $barony-secondary, 60%); //tint($barony-secondary, 60%);
$barony-responsive: mix(white, $barony-primary, 18%); //tint($barony-primary, 18%);

// old styles
// $barony-primary: #298FC2;
// $barony-secondary: #ffc72c;
// $barony-light-border: #ffe395;
// $barony-bg: #fff9e9;
// $barony-bg-landingboxes: #fffbf0;
// $barony-light-border-landingboxes: #ffeebf;
// $barony-bg-blue: #e9f4f9;
// $barony-dark-blue: #177ba3;
// $barony-light-blue: #cee6f1;
// $barony-border: mix(white, $barony-secondary, 60%); //tint($barony-secondary, 60%);
// $barony-responsive: mix(white, $barony-primary, 18%); //tint($barony-primary, 18%);


//Your Place
$yourplace-primary: #00B5E2;
$yourplace-secondary: #00558C;
$yourplace-news-bg: #d9f5fc;
$yourplace-news-bottom: #00bfe8;
$yourplace-gray-bg: #D6D2C4;
$yourplace-gray-light-bg: #EFEDE7;

//Lowther Homes
$lowther-primary: #c4d600;
$lowther-light-gray: #b0b1b2; // Used as the border for the quote carousel slides
$lowther-dark-gray: #58595b;
$lowther-bg-light-gray: #f6f7f7;
$lowther-links: #788600;
$lowther-font-dark: #262626;
$lowther-font-grey: #7f7f7f;
$lowther-font-tile: #737373;
$lowther-mobile-nav: #222222;
$lowther-mobile-nav-primary: #444444;
$lowther-mobile-nav-secondary: #f6f7da;

//Wheatley Group
$wheatley-primary: #572932;
$wheatley-primary-shade1: #683f47;
$wheatley-primary-shade2: #79545b;
$wheatley-secondary: #b7a99a;
$wheatley-secondary-brighter: #cdc3b8;
$wheatley-light-bg: #f1eeeb;
$wheatley-medium-bg: #f4f2f0;
$wheatley-yellow: #ffc72c;
$wheatley-yellow-light: #ffe395;
$wheatley-footer-bg: #e9e5e1;
$wheatley-footer-text: #858585;