/* Module: S */
// Slick styles
.slick-list {
    position: relative;
    overflow: hidden;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-slideshow__slide img {
    margin: 0 auto;
}

.slick-next {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 0;
}

.slick-prev {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0;
}

.slick-dots {
    margin-top: 1em;
    text-align: center;
    @extend %list-reset;
    & li {
        @include sq-inline-block;
        & + li {
            margin-left: 1em;
        }
    }
}

.carousel {
  display: none;
  margin-bottom: 0.5em;

  img {
    width: 100%;
    height: auto;
  }

  .slick-slide {
    height: 250px;
    position: relative;

    @media (min-width: 768px) and (max-width: 991px) {
      height: 175px;
      .carousel__caption {
        width: 44%;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 205px;
    }
    @media screen and (min-width: 1280px) {
      height: 260px;
    }
  }

  .slick-next {
    @extend .icon-arrow-right;
    right: -52px;
    background-position: 18px center;
    @media (max-width: 1024px) {
      right: 0px;
    }
  }

  .slick-prev {
    @extend .icon-arrow-left;
    left: -52px;
    background-position: 15px center;
    @media (max-width: 1024px) {
      left: 0px;
    }
  }

  .slick-next,
  .slick-prev {
    width: 42px;
    height: 42px;
    background-color: #ccc;
    border: none;
    @include sq-border-radius(50%);
    background-repeat: no-repeat;
    background-size: 10px 20px;
    z-index: 2;
    margin-top: -21px;
  }

  &__caption {
    width: 34%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 1.5em;

    * {
      position: relative;
      z-index: 1;
    }

    p {
      font-size: 1.5em;
      margin-bottom: 16px;
    }

    &:before,
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: "";
      right: 35px;
      top: 50%;
      margin-top: -1000px;
      border-width: 1000px 0 1000px 1000px;
      border-color: transparent transparent transparent #000;
      opacity: 0.5;
    }

    &:after {
      right: -35px;
    }
  }

  &__caption-content {
    width: 75%;
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
}
