/* Module: S */
.wheatley-landing1 {
  position: relative;
  .landingbg {
    position: absolute;
    margin-top: -1.32em;
    width: 100%;
    height: 600px;
    overflow: hidden;
    @media (max-width: 1100px) {
     display: none;
    }
    &:before {
      content: " ";
      position: absolute;
      right: 1035px;
      bottom: -2500px;
      width: 5000px;
      height: 5000px;
      background-color: white;
      transform: rotate(45deg);
    }
    &:after {
      content: " ";
      position: absolute;
      right: -150px;
      bottom: -150px;
      width: 300px;
      height: 300px;
      background-color: $wheatley-secondary;
      transform: rotate(45deg);
    }
  }
}