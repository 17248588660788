/* Module: S */
//.tiles {
//  margin: 0;
//  display: -webkit-box;
//  display: -moz-box;
//  display: -ms-flexbox;
//  display: -webkit-flex;
//  display: flex;
//  -webkit-flex-wrap: wrap;
//  flex-wrap: wrap;
//  @media (max-width: 768px) {
//    margin-bottom: 8px;
//  }
//}
.bootstrap-fix1 {
  width: calc(25% - 10px) !important;
  @media (max-width: 992px) {
    width: calc(50% - 10px) !important;
  }
  @media (max-width: 768px) {
    width: calc(100% - 10px) !important;
    margin-bottom: 0px !important;
  }
}

.bootstrap-fix2 {
  width: calc(50% - 10px) !important;
  @media (max-width: 768px) {
    width: calc(100% - 10px) !important;
    margin-bottom: 0px !important;
  }
}

.tile-item, .tile-item2 {
  margin: 0px 5px 8px 5px;
  background-color: $medium-grey;
  background-position: right 1em bottom 1em;
  background-repeat: no-repeat;
  border: 2px solid #ccc;
  @include sq-border-radius(3px);
  padding: 20px 10px 10px 10px;

  position: relative;
  min-height: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    @include sq-border-radius(0px);
    background-position-y: 50%;
  }

  &__header {
    margin: 0 0 16px;
    font-weight: 200;
    font-size: 24px;
    line-height: 1.2rem;
    a {
      color: #222222;
      padding: 0;
    }
    a:hover {
      .gha-cube &, .gha-wlhp &, .loretto &, .barony &, .yourplace & {
        color: #222222;
      }
      .dunedin & {
        color: #222222;
        padding: 0;
      }
    }
    a:before {
      content: none;
    }
  }

  a {
    @extend .link-arrow;
    font-weight: 200;
    display: block;
  }
  p {
    margin-bottom: 0.8em;
    font-weight: 200;
    padding-right: 90px;
    @media (max-width: 768px) {
      padding-right: 0px;
    }

  }

  &.icon-pliers {
    background-image: url('{{file_dest}}/tile-icon-pliers.png');
    background-image: url('{{file_dest}}/tile-icon-pliers.svg');
    background-size: 50px auto; // 105px;
  }

  &.icon-pound {
    background-image: url('{{file_dest}}/tile-icon-pound.png');
    background-image: url('{{file_dest}}/tile-icon-pound.svg');
    background-size: 58px auto; //86px;
  }

  &.icon-house {
    background-image: url('{{file_dest}}/tile-icon-house.png');
    background-image: url('{{file_dest}}/tile-icon-house.svg');
    background-size: 89px auto; //95px;
  }

  &.icon-support {
    background-image: url('{{file_dest}}/tile-icon-support.png');
    background-image: url('{{file_dest}}/tile-icon-support.svg');
    background-size: 81px auto; //95px;
  }

  &.important {
    border-color: #f3aba1;
    background-color: #fceae7;
    @media (max-width: 768px) {
      margin-top: 15px;
    }


    &:before,
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: "";
      right: 0;
      bottom: 0;
    }

    &:before {
      border-width: 0 0 75px 75px;
      border-color: transparent transparent #de2e14 transparent;

      .gha-cube & {
        border-color: transparent transparent $cube-main-color-red transparent;
      }
      .gha-wlhp & {
        border-color: transparent transparent $wlhp-main-color-purple transparent;
      }
      .dunedin & {
        border-color: transparent transparent #ffc72c transparent;
      }
      .loretto & {
        border-color: transparent transparent $loretto-main-color-blue transparent;
      }
      .barony & {
        border-color: transparent transparent $barony-secondary transparent;
      }
      .yourplace & {
        border-color: transparent transparent $yourplace-secondary transparent;
      }
    }

    &:after {
      border-width: 0 0 50px 50px;
      border-color: transparent transparent #e5624f transparent;

      .gha-cube & {
        border-color: transparent transparent #c04d71 transparent;
      }
      .gha-wlhp & {
        border-color: transparent transparent #c350a4 transparent;
      }
      .dunedin & {
        border-color: transparent transparent #ffe395 transparent;
      }
      .loretto & {
        border-color: transparent transparent $loretto-main-color-blue-lightest transparent;
      }
      .barony & {
        border-color: transparent transparent $barony-light-border transparent;
      }
      .yourplace & {
        border-color: transparent transparent $yourplace-news-bottom transparent;
      }
    }

    .gha-cube & {
      background-color: #f8ebef;
      border-color: #e0a6b8;
    }
    .gha-wlhp & {
      background-color: #f9edf5;
      border-color: #e3aed4;
    }
    .dunedin & {
      background-color: #fff9e9;
      border-color: #ffe395;
    }
    .loretto & {
      background-color: $loretto-light-bg;
      border-color: $loretto-light-border;
    }
    .barony & {
      background-color: $barony-bg;
      border-color: $barony-light-border;
    }
    .yourplace & {
      background-color: $yourplace-news-bg;
      border-color: $yourplace-news-bottom;
    }

  }
}

.tile-item2 {
  margin: 0px 5px 18px 15px;
  padding: 10px;
  width: 250px;
  min-height: 0;
  height: 122px;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }
  @media (max-width: 539px) {
    width: 100%;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    @include sq-border-radius(0px);
  }
  &__header {
    margin: 0;
    a {
      line-height: 30px;
    }
  }
  &.icon-pliers {
    background-image: url('{{file_dest}}/tile-icon-pliers.png');
    background-image: url('{{file_dest}}/tile-icon-pliers.svg');
    background-size: 44px auto; // 105px;
  }

  &.icon-pound {
    background-image: url('{{file_dest}}/tile-icon-pound.png');
    background-image: url('{{file_dest}}/tile-icon-pound.svg');
    background-size: 50px auto; //86px;
  }

  &.icon-house {
    background-image: url('{{file_dest}}/tile-icon-house.png');
    background-image: url('{{file_dest}}/tile-icon-house.svg');
    background-size: 81px auto; //95px;
  }
}

.accordion {
  .tile-item2 {
    h2 {
      background-color: transparent;
      font-weight: 200;
      font-size: 24px;
      line-height: 1.2rem;
      padding: 0;
      a:hover {
        .gha-cube &, .gha-wlhp &, .dunedin &, .loretto &, .barony &, .yourplace & {
          color: #222222;
        }
      }
    }
    &__text{
      p {
        padding-top: 12px;
      }
    }
  }
}

@media (max-width: 991px) {
  .tile-item {
    &.important {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

.aftertilebreaker {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 7px;
}

//@media (max-width: 991px) {
//  .tile-item {
//    width: calc(50% - 10px);
//  }
//}
//
//@media (max-width: 767px) {
//  .tile-item {
//    width: 100%;
//    margin-bottom: 0;
//    border: none;
//    border-top: 2px solid #ccc;
//    border-radius: 0;
//
//    .half-width & {
//      width: 100%;
//    }
//  }
//}
