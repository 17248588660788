/* Module: S */
.lowther-top {
  margin-top: -1.32em;
  position: relative;
  overflow: hidden;
  padding-top: 250px;
  .container {
    @media (max-width: 991px) {
      background-color: black;
    }
  }
  &__content, &__contentnoblack {
    max-width: 660px;
    background-color: black;
    padding: 25px 100px 25px 0;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      background-color: black;
      content: " ";
      width: 50vw;
    }
    h1 {
      margin-top: 0;
      font-size: 31px;
      color: $lowther-primary;
      font-weight: 300;
      margin-bottom: 15px;
    }
    p, a {
      color: white;
      margin-bottom: 15px;
      font-weight: 200;
    }
    a:hover {
      color: white;
    }
  }

  &__contentnoblack {
    background-color: transparent;
    &:before {
      background-color: transparent;
    }
    h1 {
      color: white;
    }
  }

}