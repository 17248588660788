/* Module: S */
.office-content {
  .find-office-side {
    margin-bottom: 33px;
    min-height: 272px;

    h2 {
      border-top: none;
      border-bottom: 1px solid #ccc;
      color: $font-grey;
      padding-bottom: 10px;
      font-size: 1.5em;
      font-weight: 700;
      margin: 0.67em 0;
      .wheatley & {
        border-bottom: 1px dotted $wheatley-secondary;
        font-family: Brauer, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        color: $wheatley-primary;
      }
    }
    h3 {
      font-size: 1.5em;
      font-weight: 300;
      .lowther & {
        font-weight: normal;
      }
      color: $font-grey;
      margin-top: 0px;
      margin-bottom: 30px;
      line-height: pxToRem(31);
      .wheatley & {
        font-weight: 300;
        font-size: 1.5em;
        color: $wheatley-secondary;
        font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
    p {
      font-weight: 300;
      //.lowther & {
      //  font-weight: normal;
      //}
      color: $font-grey;
      margin-bottom: 0;
      a {
        font-weight: 300;
        color: $brand-primary;
        &:after {
          content: " >";

        }

        .gha-cube & {
          color: $cube-main-color-green;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-blue;
        }
        .dunedin & {
          color: $dunedin-main-color-blue;
        }
        .loretto & {
          color: $loretto-main-color-blue;
        }
        .barony & {
          color: $barony-primary;
        }
        .yourplace & {
          color: $yourplace-primary;
        }
        .lowther & {
          color: $lowther-primary;
          font-weight: normal;
        }
        .wheatley & {
          color: $wheatley-primary;
        }
      }
    }

  }
  .accordion {
    .side {
      padding: 0 30px;
      p {
        color: #666666;
        margin-bottom: 0.4em;
        font-weight: 300;
        //.lowther & {
        //  font-weight: normal;
        //}
        b {
          padding-top: 1em;
          display: block;
        }
      }
      .opening {
        width: 100%;
        clear: both;
        .day {
          float: left;
        }
        .time {
          float: right;
        }
      }
      .facilities {
        clear: both;
        ul {
          list-style: none;
          display: inline-block;
          padding: 0;
          margin: 0;
          li {
            display: block;
            float: left;
            width: 36px;
            height: 36px;
            margin: 0 8px 11px 0;
            &.fac_park {
              background: url(mysource_files/fac_park.svg) 50% 50%/36px 36px no-repeat;
              .gha-cube & {
                background: url(mysource_files/gha-cube-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .gha-wlhp & {
                background: url(mysource_files/gha-wlhp-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .dunedin & {
                background: url(mysource_files/dunedin-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .loretto & {
                background: url(mysource_files/loretto-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .barony & {
                background: url(mysource_files/barony-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .yourplace & {
                background: url(mysource_files/yourplace-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .lowther & {
                background: url(mysource_files/lowther-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
              .wheatley & {
                background: url(mysource_files/wheatley-fac_park.svg) 50% 50%/36px 36px no-repeat;
              }
            }
            &.fac_wifi {
              background: url(mysource_files/fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              .gha-cube & {
                background: url(mysource_files/gha-cube-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .gha-wlhp & {
                background: url(mysource_files/gha-wlhp-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .dunedin & {
                background: url(mysource_files/dunedin-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .loretto & {
                background: url(mysource_files/loretto-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .barony & {
                background: url(mysource_files/barony-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .yourplace & {
                background: url(mysource_files/yourplace-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .lowther & {
                background: url(mysource_files/lowther-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
              }
              .wheatley & {
                background: url(mysource_files/wheatley-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
            }
            &.fac_disab {
              background: url(mysource_files/fac_disab.svg) 50% 50%/36px 36px no-repeat;
              .gha-cube & {
                background: url(mysource_files/gha-cube-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .gha-wlhp & {
                background: url(mysource_files/gha-wlhp-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .dunedin & {
                background: url(mysource_files/dunedin-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .loretto & {
                background: url(mysource_files/loretto-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .barony & {
                background: url(mysource_files/barony-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .yourplace & {
                background: url(mysource_files/yourplace-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .lowther & {
                background: url(mysource_files/lowther-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
              .wheatley & {
                background: url(mysource_files/wheatley-fac_disab.svg) 50% 50%/36px 36px no-repeat;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .office-content {
    .find-office-side {
      min-height: 303px;
    }
  }
}

@media (max-width: 991px) {
  .office-content {
    .find-office-side {
      min-height: 0;
    }
  }
}
