/* Module: S */
// force update
.video-widget {
  position: relative;
  background-color: #000;
  margin-bottom: 24px;
}

.video-overlay {
  cursor: pointer;
  position: relative;
  z-index: 1;

  h2 {
    color: #fff;
    font-size: 1.125em;
    //font-weight: lighter;
    //margin-bottom: 1em;
    font-weight: 400;
    margin: 1.195em 0 1.195em 0;
  }

  // &:after {
  //   background-image: url('{{file_dest}}/video-play.png');
  //   background-position: 50% 50%; // 50% 52%;
  //   background-repeat: no-repeat;
  //   background-size: 110px; // 105px;
  //   bottom: 0;
  //   content: '';
  //   height: 100%;
  //   left: 0;
  //   opacity: 0;
  //   pointer-events: none;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   transition: opacity 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
  //   width: 100%;
  // }
  //
  // &:hover:after {
  //   // background-position: 50% 50%;
  //   // background-size: 110px;
  //   opacity: 0.8;
  // }
}

.video-text {
  position: absolute;
  //background-color: rgba(122, 122, 122, 0.8);
  border-radius: 0 0 2px 2px;
  color: #fff;
  left: 0;
  bottom: 0;
  //padding: 0 174px 0 24px;
  font-weight: lighter;
  right: 0;
  z-index: 1;
  padding: 0 20px 0 82px;
  background-color: #1a1a1a;

  p {
    font-size: 0.875em;
  }
  h2 {
    .wheatley & {
      font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
    }
  }
}

.video-thumbnail {
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.video-play {
  //width: 150px;
  height: 100%;
  //background: rgba(170, 170, 170, 0.8) url('{{file_dest}}/video-play.png') no-repeat 50% 50%;
  // background: #444 url('{{file_dest}}/video-play.png') no-repeat 50% 50%;
  background: #444;
  transition: background-color 0.3s;
  // background-size: 78px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 62px;
  //background: #000;
  left: 0;
  // transition: background-size 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  //
  // .video-overlay:hover & {
  //   background-size: 88px;
  // }
  &:hover {
    background: $brand-primary;
    transition: background-color 0.3s;

    .gha-cube & {
      background-color: $cube-main-color-green;
    }
    .gha-wlhp & {
      background-color: $wlhp-main-color-blue;
    }
    .dunedin & {
      background-color: $dunedin-main-color-yellow;
    }
    .loretto & {
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      background-color: $dunedin-main-color-yellow;
    }
    .yourplace & {
      background-color: $yourplace-primary;
    }
    .wheatley & {
      background-color: $wheatley-secondary;
    }
  }
}
