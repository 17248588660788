/* Module: S */
.lowther-checklist {
  padding: 80px 0;
  background-color: black;
  h3 {
    color: white;
    font-size: 31px;
    border-bottom: 4px solid $lowther-primary;
    padding-bottom: 25px;
    margin-bottom: 10px;
  }
  .list-check_outside {
    margin: 40px 0;
    li {
      color: white;
      font-weight: 500;
    }
  }
}