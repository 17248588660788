/* Base Modding from standard bootstrap */

// Body reset
// --------------------------------------------------

$font-family-sans-serif:  FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:          16px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.25; // 20/14 = 1.428571429; 20/16 = 1.25;
//** Background color for `<body>`.
$body-bg:               $gray-lighter;
//** Global text color on `<body>`.
$text-color:            $gray-darker;

html {
  font-size: $font-size-base;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  -webkit-overflow-scrolling: touch;
  //&.yourplace {
  //  color: $font-grey;
  //}
}

body {
  font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}




// Links
// --------------------------------------------------

//** Global textual link color.
$link-color: $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

a {
  color: $brand-primary;

  .gha-cube & {
    color: $cube-main-color-red;
  }
  .gha-wlhp & {
    color: $wlhp-main-color-purple;
  }
  .dunedin & {
    color: #002E6B;
  }
  .loretto & {
    color: $loretto-main-color-darkblue;
  }
  .barony & {
    color: $barony-primary;
  }
  .yourplace & {
    color: $yourplace-primary;
  }
  .lowther & {
    color: $lowther-links;
    font-weight: normal;
  }
  .wheatley & {
    color: $wheatley-primary;
    font-weight: normal;
  }

  text-decoration: none;

  &:hover,
  &:focus {
    color: $brand-primary;
    text-decoration: $link-hover-decoration;

    .gha-cube & {
      color: $cube-main-color-red;
    }
    .gha-wlhp & {
      color: $wlhp-main-color-purple;
    }
    .dunedin & {
      color: #002E6B;
    }
    .loretto & {
      color: $loretto-main-color-darkblue;
    }
    .barony & {
      color: $barony-primary;
    }
    .yourplace & {
      color: $yourplace-primary;
    }
    .lowther & {
      color: $lowther-links;
    }
    .wheatley & {
      color: $wheatley-primary;
    }
  }

  &:focus {
    @include tab-focus;
  }
}
