/* Module: S */

.news_n_blogs {
  margin-bottom: 10px;
  position: relative;

  &__arrows {
    position: absolute;
    right: 5px;
    top: 10px;

    .icon-arrow {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-color: #ccc;
      border: none;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: 10px 20px;
      z-index: 2;
      margin-top: -21px;

      &.disactive {
        opacity: 0.5;
      }

      &-left {
        left: -52px;
        background-position: 15px center;
      }

      &-right {
        right: -52px;
        background-position: 18px center;
      }
    }
  }

  .news_list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    color: #7a7a7a;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .newswide, .news {
    border-bottom: 10px solid $white;
    border-radius: 3px;
    background-clip: padding-box;
    border: 5px solid transparent;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .img-wrapper {
      position: relative;
      padding-bottom: 230px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
    }

    .news-wrapper {
      padding: 20px;

      .titlewrapper {
        height: 80px;
        overflow: hidden;
        margin-bottom: 5px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: pxToRem(18);
      line-height: pxToRem(26);
      margin-top: 0;
      padding-right: 30px;

      a {
        //color: #333333;
      }

      & span {
        color: $brand-primary;

        .gha-cube & {
          color: $cube-main-color-red;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-purple;
        }
        .dunedin & {
          color: #002E6B;
        }
        .loretto & {
          color: $loretto-main-color-darkblue;
        }
        .barony & {
          color: $barony-primary;
        }
        .yourplace & {
          color: $yourplace-secondary;
        }
        .lowther & {
          color: $lowther-links;
        }
        .wheatley & {
          color: $wheatley-primary;
          font-weight: 700;
          font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
      }
    }

    p {
      font-weight: 300;
      margin-top: 0px;
    }
  }
  &.listing_page {
    .newswide, .news {
      p {
        margin-bottom: 0px;
      }
      .news-wrapper {
         //height: 250px;
        .titlewrapper {
          //min-height: 100px;
          height: auto;
          //height: 80px;
        }
      }
    }
  }
  //&.listing_page {
  //  .news {
  //    p {
  //      margin-bottom: 0px;
  //    }
  //    .news-wrapper {
  //      min-height: 315px;
  //      .titlewrapper {
  //        min-height: 100px;
  //      }
  //    }
  //  }
  //}
  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.goog-te-gadget img {
  margin-bottom: 0;
}

.newswide {
  background-color: $light-bg;
  .gha-cube & {
    background-color: $cube-light-bg;
  }
  .gha-wlhp & {
    background-color: #f9edf5;
  }
  .dunedin & {
    background-color: #e5eaf0;
  }
  .loretto & {
    background-color: $loretto-light-bg;
  }
  .barony & {
    background-color: $barony-bg-blue;
  }
  .yourplace & {
    background-color: $yourplace-news-bg;
  }
  .lowther & {
    background-color: $lowther-mobile-nav;
  }
  .wheatley & {
    background-color: $wheatley-light-bg;
  }

  .news-wrapper {
    .titlewrapper {
      border-bottom: 1px solid #F6C0B8;
      .gha-cube & {
        border-bottom: 1px solid $cube-light-border;
      }
      .gha-wlhp & {
        border-bottom: 1px solid $wlhp-light-border;
      }
      .dunedin & {
        border-bottom: 1px solid $dunedin-light-border;
      }
      .loretto & {
        border-bottom: 1px solid $loretto-main-color-blue-lighter;
      }
      .barony & {
        border-bottom: 1px solid $barony-light-blue;
      }
      .yourplace & {
        border-bottom: 1px solid $yourplace-news-bottom;
      }
      .lowther & {
        border-bottom:  0px ;
      }
      .wheatley & {
        border-bottom: 1px dotted $wheatley-secondary;
      }
      h4 {
        a {
          .lowther & {
            color: $lowther-primary;
            font-weight: bold;
          }
          span {
            .lowther & {
              color: $lowther-primary !important;
              font-weight: bold;
            }
          }
        }
      }
    }
    p {
      .lowther & {
        color: white;
      }
    }
  }
}

.news {
  background-color: #F2F2F2;
  .wheatley & {
    background-color: $wheatley-light-bg;
  }

  .news-wrapper {
    .titlewrapper {
      border-bottom: 1px solid #BFBFBF;
      .wheatley & {
        border-bottom: 1px dotted $wheatley-secondary;
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .news_n_blogs {
    &__arrows {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .news_list {
    .homepage:not(.active) {
      display: none;
    }

    .homepage {
      width: 100%;
    }
  }
}
