/* Module: S */
.lowther-yellowstrip {
  padding: 30px 0 ;
  background-color: $lowther-primary;
  text-align: center;
  h3 {
    font-size: 22px;
    margin: 20px 0;
    text-align: left;
  }
  p {
    margin-bottom: 20px;
    text-align: left;
  }
  .button {
    margin: 0 auto;
    color: white !important;
    background-color: black;
    &:hover {
      color: white !important;
      background-color: black;
    }

  }
}