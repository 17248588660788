/* Module: S */
.latestnews {
  background-color: $black;
  color: $white;
  line-height: 50px;
  margin-top: -2px;
  z-index: 2;
  position: relative;
  display: none;

  .marquee {
    position: relative;
    overflow: hidden;
    height: 50px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    @media (max-width: 991px) {
      margin-left: 0px;
      margin-right: 0px;
    }
    .news {
      animation: marquee 20s linear infinite;
      position: absolute;
      z-index: 10;
      width: 200%;
      padding: 0;
      background-color: $black;
      height: 35px;
      overflow: hidden;
      @media (max-width: 991px) {
        animation: marquee2 20s linear infinite;
        width: 320%;
        padding-top: 10px;
        line-height: 25px;
      }
      @media (max-width: 600px) {
        animation: marquee3 20s linear infinite;
        width: 500%;
      }
      @keyframes marquee {
        0% {
          left: 0;
        }
        100% {
          left: -100%;
        }
      }
      @keyframes marquee2 {
        0% {
          left: 0;
        }
        100% {
          left: -160%;
        }
      }
      @keyframes marquee3 {
        0% {
          left: 0;
        }
        100% {
          left: -250%;
        }
      }
      span {
        min-width: 50%;
        float: left;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover {
         animation-play-state: paused;
      }
    }
  }
  .latest {
    background-color: #ffc72c;
    color: $black;
    text-align: center;
    max-width: 70px;
  }

  .more-wrapper {
    @media (min-width: 992px) {
      float: right;
    }
  }

  .more {
    padding: 0 30px;
    padding-left: 0px;
    a {
      text-align: center;
      color: #ffc72c;
      margin-right: 12px;
    }
    .sr-only {
      color: $white;
    }

    @media (max-width: 991px) {
      text-align: right;
    }
  }
  .close {
    position: absolute;
    width: 12px;
    height:12px;
    top: 19px;
    right: 15px;
    float: right;
    opacity: 1;
    text-shadow: none;
    background: url(mysource_files/closeicon.png) 50% 50%/12px 12px no-repeat;
    background: url(mysource_files/closeicon.svg) 50% 50%/12px 12px no-repeat;
    color: #ffc72c;
    a {
      color: #ffc72c;
    }
  }
}
