/* Module: S */
.message-box {
  &__content {
    color: white;
    padding: 20px 0;

    &.center {
      text-align: center;
    }

    h1 {
      font-size: 44px;
      font-weight: 300;
    }

    p {
      font-size: 24px;
    }
  }

  &.top-bot-border {
    border-top: 20px solid #444;
    border-bottom: 20px solid #444;
  }
}

.lowther .message-box {
  background-color: #637511;
}