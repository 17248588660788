/* Module: S */
.landing-page-content {
  display: table;
  h1 {
    color: $brand-primary;

    .gha-cube & {
      color: $cube-main-color-green;
    }
    .gha-wlhp & {
      color: $wlhp-main-color-blue;
    }
    .dunedin & {
      color: $dunedin-main-color-blue;
    }
    .loretto & {
      color: $loretto-main-color-blue;
    }
    .barony & {
      color: $barony-primary;
    }
    .yourplace & {
      color: $yourplace-secondary;
    }
    .lowther & {
      color: $lowther-primary;
    }
    .wheatley & {
      color: $wheatley-primary;
    }

    font-size: 2.25em;
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  h2 {
    color: #737373;
    font-size: 1.5em;
    line-height: pxToRem(31);
    font-weight: 200;
    margin-bottom: 30px;
    margin-top: 0;
    .lowther & {
      color: $lowther-font-grey;
    }
    .wheatley & {
      color: $wheatley-secondary;
      font-size: pxToEm(30);
      line-height: pxToRem(36);
    }
  }

  p {
    // margin-bottom: pxToEm(50);
    color: #222;
    font-weight: 200;
    &:last-child {
      margin-bottom: pxToEm(50);
      .wheatley & {
        margin-bottom: pxToEm(60);
      }
    }
  }

  .accordion-title {
    h2 {
      margin-bottom: 0px;
    }
  }
  .landing-page-content__bottom {
    float: none;
    display: table-cell;
    vertical-align: bottom;
    &.text {
      padding-right: 7%;
      vertical-align: top;
      @media (max-width: 767px) {
        padding-right: 15px;

      }
    }
  }

  &__img {
    max-height: 250px;
    margin-bottom: pxToEm(50);
    float: right;
  }
//
}
