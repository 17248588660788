/* Module: S */
.yourplace-bluestripe {
  background-color: $yourplace-secondary;
  font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0;
  div[class^="col-md"] {
    min-height: 200px;
    @media (max-width: 991px) {
      min-height: 160px;
    }
    @media (max-width: 767px) {
      min-height: 130px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 30px;
      color: white;
      margin-bottom: 0;
      line-height: 40px;
      padding-right: 55px;
      @media (max-width: 991px) {
        padding-right: 0;
      }
      &.percent {
        font-size: 132px;
        font-weight: 700;
        padding-left: 0px;
        @media (max-width: 767px) {
          font-size: 70px;
        }
      }
    }
  }
}