/* Module: S */
.yourplace-news {
  padding: 30px 0;
  h2 {
    text-align: center;
    font-size: 45px;
    color: $yourplace-secondary;
    margin-bottom: 20px;
    .lowther & {
      color: $black;
      font-size: 31px;
      padding-bottom: 20px;
      border-bottom: 4px solid $lowther-primary;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
  .row.is-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    [class*='col-']:first-child {
      border-right: 2px solid $yourplace-secondary;
      @media (max-width: 991px) {
        border-right: 0px;
      }
      .lowther & {
        //border-right: 2px solid #58595b;
        border-right:0;
      }
    }
  }

  .row.is-flex > [class*='col-'] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-self: stretch;
    @media (max-width: 991px) {
      display: block;
    }

  }
  .pad8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .leftnews {
    //flex: 1;
    background-color: $yourplace-news-bg;
    .lowther & {
      background-color: $lowther-mobile-nav;
    }
    .img-wrapper {
      position: relative;
      padding-bottom: 337px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
    }
    .news-wrapper {
      padding: 18px 28px 15px 28px;
      overflow: hidden;
      @media (max-width: 991px) {
        padding: 15px;
        margin-bottom: 15px;
      }
      .titlewrapper {
        font-size: 24px;
        .date {
          .lowther & {
            display:none;
          }
        }
        h4 {
          font-weight: 700;
          a {
            .lowther & {
              color: $lowther-primary;
              font-weight:bold;
            }
          }
        }
        p {
          font-size: 14px;
          margin-bottom: 15px;
        }
        a {
          .lowther & {
            color: $black;
          }
        }
      }
      p {
        margin-bottom: 0;
        .lowther & {
          color: white;
          min-height:108px;
        }
      }
      a {
        .lowther & {
          color: $lowther-primary;
        }
      }
    }
  }
  .rightnews {
    margin-top: 16px;
    border-bottom: 2px solid $yourplace-secondary;
    .lowther & {
      border-bottom: 2px solid #58595b;
    }
    height: 35%;
    &:first-child {
      margin-top: 0;
    }
    overflow: hidden;
    .leftcol {
      float: left;
      width: 170px;
      height: 170px;
      overflow: hidden;
      position: relative;
      .to-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 150%;
      }
    }
    .rightcol {
      float: left;
      width: calc(100% - 185px);
      .news-wrapper {
        padding: 0 0 0 20px;
        .titlewrapper {
          font-size: 18px;
          .date {
            .lowther & {
              display:none;
            }
          }
          h4 {
            margin-top: 0;
            font-weight: 700;
          }
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &:nth-child(3) {
      border-bottom: 0px;
      height: 30%;
    }
  }

}