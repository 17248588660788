/* Module: S */
.yourplace-bubbles {
  position: relative;
  padding: 20px 0 30px 0;
  width: 100%;
  &__content {
    padding: 40px 50px 45px 85px;
    background: url('mysource_files/yourplace-bubble-intro.svg') 50% 50% / 100% 100% no-repeat;
    margin-bottom: 20px;
    //margin-left: 60%;
    float: right;
    max-width: 500px;
    @media (max-width: 991px) {
      margin-left: 15px;
      margin-right: 15px;
      max-width: 100%;
    }
    p {
      color: white;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 700;
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
      &.quote {
        font-weight: normal;
      }
    }
  }
  &__content2 {
    padding: 40px 50px 45px 85px;
    margin-bottom: 20px;
    max-width: 500px;
    background: url('mysource_files/yourplace-bubble_reveresed.svg') 50% 50% / 100% 100% no-repeat;
    @media (max-width: 991px) {
      margin-left: 15px;
      margin-right: 15px;
      max-width: 100%;
    }
    p {
      color: white;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 700;
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
      &.quote {
        font-weight: normal;
      }
    }
  }
}