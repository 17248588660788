/* Module: S */
.lowther-news {
    &__header {
        color: #788600;
        font-size: 42px;
        font-weight: 300;
        display: inline-block;
    }

    &__link {
        font-size: 18px;
        text-decoration: underline;
        margin-left: 30px;
        padding-left: 25px;
        position: relative;
        transform: translateY(-5px);
        display: inline-block;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -3px;
            left: 0;
            width: 21px;
            height: 30px;
            background-image: url("{{file_dest}}/green-arrow.svg");
            background-repeat: no-repeat;
            background-size: cover;

        }
    }

    &__item {
        &:hover {
            text-decoration: none;

            .news-item__header {
                text-decoration: underline;
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }
}

.news-item {
    &__image {
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__header {
        font-size: 20px;
        font-weight: 600;
    }

    &__text {
        color: black;
        font-size: 18px;
        line-height: 1.3em;
    }
}