/* Module: S */

.main {
  .search-overlay {
    position: relative;
    background: none;
    display: block;
    z-index: 1;

    .search-overlay-inner {
      margin-top: 4%;
    }
  }
}

.search-overlay {
  top: 0;
  left: 0;
  display: none;
  z-index: 3;
  .wheatley & {
    z-index: 5;
  }
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.96);
  position: absolute;
  fieldset {
    border: 0px;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    legend {
      display: none;
    }
  }
  .search-overlay-inner {
    margin-top: 8.8%;
    .search_suggestion {
      font-size: 1em;
      color: #333333;
      font-weight: 300;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
    .suggestion_list {
      @extend %list-reset;
      li {
        font-size: 1.5em;
        color: $font-grey;
        font-weight: 700;
        padding: 10px;
      }
    }
    .searchresultinput-wapper {
      position: relative;
      width: calc(100% - 138px);
      .yourplace &, .lowther & {
        width: calc(100% - 140px);
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      .wheatley & {
        width: calc(100% - 141px);
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      float: left;
      @media (max-width: 576px) {
        width: 100%;
      }
      svg {
        position: absolute;
        right: 2%;
        top: 10px;
        width: 20px;
        .search {
          fill: #e22e14;
          .gha-cube & {
            fill: $cube-main-color-red;
          }
          .gha-wlhp & {
            fill: $wlhp-main-color-purple;
          }
          .dunedin & {
            fill: $dunedin-main-color-green;
          }
          .loretto & {
            fill: $loretto-main-color-blue;
          }
          .barony & {
            fill: $barony-secondary;
          }
          .yourplace & {
            fill: $yourplace-secondary;
          }
          .lowther & {
            fill: $lowther-primary;
          }
          .wheatley & {
            fill: $wheatley-primary;
          }
        }
      }
    }
  }
  .searchoverlayinput {
    float: left;
    height: 40px;
    padding: 0.5em;
    border: 1px solid #999999;
    width: 100%;
    color: $font-grey;
    font-size: 1em;
    font-weight: 300;
    background: url(mysource_files/search-gha.svg) 98% center/24px 24px no-repeat;
    .gha-cube & {
      background: url(mysource_files/search-cube.svg) 98% center/24px 24px no-repeat;
    }
    .gha-wlhp & {
      background: url(mysource_files/search-wlhp.svg) 98% center/24px 24px no-repeat;
    }
    .dunedin & {
      background: url(mysource_files/search-dunedin.svg) 98% center/24px 24px no-repeat;
    }
    .loretto & {
      background: url(mysource_files/search-loretto.svg) 98% center/24px 24px no-repeat;
    }
    .barony & {
      background: url(mysource_files/search-barony.svg) 98% center/24px 24px no-repeat;
    }
    .yourplace & {
      background: url(mysource_files/search-yourplace.svg) 98% center/24px 24px no-repeat;
    }
    .lowther & {
      background: url(mysource_files/search-lowther.svg) 98% center/24px 24px no-repeat;
    }
    .wheatley & {
      height: 48px;
      background: url(mysource_files/search-wheatley.svg) 98% center/24px 24px no-repeat;
    }
  }
  .buttonoverlay {
    @extend .button;
    float: left;
    margin-left: 10px;
    height: 40px;
    font-weight: 200;
    padding-top: 9px;
    .wheatley & {
      height: 48px;
    }
    @media (max-width: 576px) {
      margin-top: 25px;
      margin-left: 50%;
      transform: translateX(-75px);
    }
    a {
      color: $white;
    }
  }
}
