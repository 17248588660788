/* Module: S */
.duedate {

  &__list {

    ul {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
}
