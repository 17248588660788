/* Module: S */
.main {
  .search-overlay {
    position: relative;
    background: none;
    display: block;
    z-index: 1;

    .search-overlay-inner {
      margin-top: 4%;
    }
  }
}

.search-main-body {
  top:0;
  left:0;
  z-index: 3;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#ffffff, 0.9);
  fieldset {
    border: 0px;
    margin-bottom: 20px;
  }
  .search-overlay-inner {
    margin-top: 2em;
    .search_suggestion {
      font-size: 1em;
      color: #333333;
      font-weight: 300;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
    .suggestion_list {
      @extend %list-reset;
      li {
        font-size: 1.5em;
        color: $font-grey;
        font-weight: 700;
        padding: 10px;
        a {
          padding-left:20px;
          color: #333333;
          &:hover {
            color: $brand-primary;

            .gha-cube & {
              color: $cube-main-color-green;
            }
            .gha-wlhp & {
              color: $wlhp-main-color-blue;
            }
            .dunedin & {
              color: $dunedin-main-color-blue;
            }
            .loretto & {
              color: $loretto-main-color-blue;
            }
            .barony & {
              color: $barony-primary;
            }
            .yourplace & {
              color: $yourplace-primary;
            }
            .wheatley & {
              color: $wheatley-primary;
            }
          }
        }
      }
    }
  }

  .searchresultinput-wapper {
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      right: 2%;
      top: 10px;
      width: 20px;
      .search{
        fill: $brand-primary;
        .gha-cube & {
          fill: $cube-main-color-red;
        }
        .gha-wlhp & {
          fill: $wlhp-main-color-purple;
        }
        .dunedin & {
          fill: $dunedin-main-color-green;
        }
        .loretto & {
          fill: $loretto-main-color-darkblue;
        }
        .barony & {
          fill: $barony-secondary;
        }
        .yourplace & {
          fill: $yourplace-secondary;
        }
      }
    }
  }

  .searchoverlayinput {
    float: left;
    height: 40px;
    padding: 0.5em;
    border: 1px solid #e4e4e4;
    width: 100%;
    color: $font-grey;
    font-size: 1em;
    font-weight: 300;
  }
  .buttonoverlay {
    @extend .button;
    float: left;
    margin-left: -15px;
    height: 40px;
    font-weight: 200;
    padding-top: 9px;
    @media (max-width: 576px) {
      margin-top: 25px;
      margin-left: 50%;
      transform: translateX(-75px);
    }
    a {
      color: $white;
    }
    &:focus {
      background-color: $brand-primary;

      .gha-cube & {
        background-color: $cube-main-color-green;
      }
      .gha-wlhp & {
        background-color: $wlhp-main-color-blue;
      }
      .dunedin & {
        background-color: $dunedin-main-color-blue;
      }
      .loretto & {
        background-color: $loretto-main-color-blue;
      }
      .barony & {
        background-color: $barony-primary;
      }
      .yourplace & {
        background-color: $yourplace-primary;
      }
    }
  }
}
