/* Module: S */
.stats_cta {
  &__search {
    @extend .main-header;
  }
  &__cards {
    &__custom {
      padding: 15px;
      border: 2px solid #572932;
      margin-bottom: 20px;
      
      h3 {
        margin-top: 0;
        font-size: 76px;
        border-bottom: 1px solid #ffc72c;
      }
    }
    .wheatleystat {
      width: 100%;
      background-color: $wheatley-primary;
      padding-bottom: 200px;
      margin-bottom: 20px;
      overflow: hidden;
      height: 0;
      position: relative;
      &:before {
        position: absolute;
        content: " ";
        background-color: white;
        width: 100px;
        height: 100px;
        transform: rotate(-45deg);
        bottom: -50px;
        right: -50px;
      }
      .ctacontent {
        width: 100%;
        padding: 15px;
        color: white;
        h3 {
          margin-top: 0;
          font-size: 76px;
          border-bottom: 1px solid $wheatley-yellow;
        }
      }
    }
    a {
      .wheatleyctared, .wheatleyctagrey, .wheatleyctayellow {
        width: 100%;
        padding-bottom: 215px;
        margin-bottom: 20px;
        overflow: hidden;
        height: 0;
        position: relative;
        &:before {
          position: absolute;
          width: 400px;
          height: 300px;
          transform: rotate(-45deg);
          content: " ";
          top: -118px;
          left: -176px;
          @media (max-width: 991px) {
            left: auto;
            width: 1000px;
            height: 1000px;
            left: auto;
            top: -500px;
            right: 207px;
          }
          z-index: 2;
        }
        &:after {
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0);
          transition-duration: 0.5s;
          z-index: 1;
        }
        .to-wrap-desk {
          left: 0;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) scale(1.8);
          width: 100%;
          z-index: 1;
          margin: 0;
          @media (max-width: 991px) {
            display: none;
          }
        }
        .to-wrap-mobi {
          display: none;
          @media (max-width: 991px) {
            display: block;
            right: 0;
            max-width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
          }
        }
        .ctacontent {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          width: 100%;
          padding: 15px;
          h3 {
            margin-top: 0;
            color: white;
            font-size: 28px;
            max-width: 63%;
            @media (max-width: 991px) {
              font-size: 23px;
              max-width: 90%;
            }
            @media (max-width: 767px) {
              max-width: 180px;
              font-size: 28px;
              max-width: 70%;
            }
          }
        }
      }
      .wheatleyctared {
        &:before {
          background-color: $wheatley-primary;
        }
        .ctacontent {
          p {
            color: $wheatley-secondary;
          }
        }
      }
      .wheatleyctagrey {
        &:before {
          background-color: $wheatley-secondary;
          //opacity: 0.1;
        }
        .ctacontent {
          p {
            color: $wheatley-primary;
          }
        }
      }
      .wheatleyctayellow {
        &:before {
          background-color: $wheatley-yellow;
          //opacity: 0.1;
        }
        .ctacontent {
          p {
            color: $wheatley-primary;
          }
        }
      }
      &:hover, &:focus {
        text-decoration: none;
        .wheatleyctared, .wheatleyctagrey, .wheatleyctayellow {
          &:after {
            background-color: rgba(0,0,0,0.2);
          }
          .ctacontent {
            p {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
