/* Module: S */
// Breadcrumbs
// --------------------------------------------------

$breadcrumb-padding-vertical:   6px;
$breadcrumb-padding-horizontal: 0;
//** Breadcrumb background color
$breadcrumb-bg:                 transparent;
//** Breadcrumb text color
$breadcrumb-color:              #aaa;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       #aaa;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          ">";
//** Additional
$breadcrumb-font-size:          0.875em;
$breadcrumb-margin-vertical:    1em;

.sq-breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  // margin-bottom: $line-height-computed;
  margin: $breadcrumb-margin-vertical 0;
  margin-top: 0;
  padding-top: 0;
  list-style: none;
  // background-color: $breadcrumb-bg;
  // border-radius: $border-radius-base;
  font-size: $breadcrumb-font-size;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $breadcrumb-color;
      .wheatley & {
        color: $wheatley-secondary;
      }
    }

    a {
      .gha-wlhp & {
        color: $wlhp-main-color-blue;
      }
      .gha-cube & {
        color: $cube-main-color-green;
      }
      .dunedin & {
        color: $dunedin-main-color-blue;
      }
      .loretto & {
        color: $loretto-main-color-darkblue;
      }
      .barony & {
        color: $barony-primary;
      }
      .yourplace & {
        color: $yourplace-secondary;
      }
      .wheatley {
        color: $wheatley-primary;
      }
    }
  }

  > .active {
    color: $font-grey;
    .wheatley & {
      color: $wheatley-secondary;
    }
  }
}
