/* Module: S */
.wheatleylibrary {
  padding: 30px 0;
  background-color: $wheatley-secondary;
  .whitebg {
    align-self: stretch;
    display: flex;
    @media (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
  .libcard, .libcarddownload, .libcardvideo {
    padding: 15px ;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    //min-height: 210px;
    &:before {
      position: absolute;
      content: " ";
      background-color: $wheatley-medium-bg;
      width: 500px;
      height: 500px;
      transform: rotate(-45deg);
      top: -170px;
      right: 25px;
      @media (max-width: 1200px) {
        top: -160px;
        right: 5px;
      }
      @media (max-width: 1024px) {
        top: -160px;
        right: 25px;
      }
      @media (max-width: 767px) {
        top: -430px;
        right: auto;
        left: -135px;
      }
    }
    &:after {
      position: absolute;
      content: " ";
      width: 30px;
      height: 35px;
      top: 15px;
      left: 87%;
      background: url(mysource_files/libcard-download.svg) 50% 50% / cover no-repeat;
      @media (max-width: 1024px) {
        left: 85%;
      }
      @media (max-width: 991px) {
        content: none;
      }
      @media (max-width: 767px) {
        position: absolute;
        content: " ";
        background-color: rgba(255, 255, 255, 0.3);
        width: 500px;
        height: 20px;
        transform: rotate(-45deg);
        top: 80px;
        //right: auto;
        left: -65px;
        z-index: 0;
      }
    }
    .flexdoc {
      display: flex;
      align-items: flex-end;
      @media (max-width: 767px) {
        display: none;
      }
    }
    h3 {
      margin: 0 0 20px 0;
      z-index: 2;
      a {
        color: $base-font-colour;
        //margin: 0 0 20px 0;
        font-size: 32px;
        font-weight: 700;
        &:hover, &:focus {
          text-decoration: none;
        }
        @media (max-width: 767px) {
          margin: 0;
          font-size: 28px;
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
      @media (max-width: 767px) {
        margin: 0;
      }
    }
    p {
      font-weight: 200;
    }
    .img-wrapper {
      margin-left: 2px;
      margin-right: 2px;
      position: relative;
      padding-bottom: 120px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
      img {
        margin: 0;
        padding: 0;
      }
    }
    .txtwrap, .txtwrap2 {
      margin-bottom: -5px;
    }
    .txtwrap {
      padding-left: 0px;
      @media (max-width: 1024px) {
        //padding-right: 0px;
      }
      @media (max-width: 991px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    .txtwrap2 {
      padding-right: 0px;
      @media (max-width: 1024px) {
        //padding-left: 0px;
      }
      @media (max-width: 991px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .libcard {
    &:after {
      background: transparent;
      @media (max-width: 991px) {
        background: #dbd4cc;
      }
    }
  }
  .libcarddownload {
    &:after {
      @media (max-width: 991px) {
        background: #dbd4cc;
      }
    }
  }
  .libcardvideo {
    &:after {
      @media (max-width: 767px) {
        position: absolute;
        content: " ";
        background-color: rgba(255, 255, 255, 0.3);
        width: 500px;
        height: 20px;
        transform: rotate(-45deg);
        top: 80px;
        //right: auto;
        left: -65px;
        z-index: 0;
      }
      width: 40px;
      height: 40px;
      left: 85%;
      background: url(mysource_files/libcard-video.svg) 50% 50% / cover no-repeat;
      @media (max-width: 991px) {
        background: #dbd4cc;
      }
    }
  }
}