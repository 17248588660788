/* Module: S */
.related-content {

  h2 {
    background-color: $brand-primary;
    border-radius: 2px 0 0 2px;
    color: #fff;
    font-size: 1.25em;
    font-weight: lighter;
    padding: 0.5em 1em;
    position: relative;
    display: inline-block;
    border-right: 12px solid $brand-primary;
    width: calc(100% - 54px);
    margin-bottom: 1em;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    .gha-cube & {
      border-right-color: $cube-main-color-green;
      background-color: $cube-main-color-green;
    }

    .gha-wlhp & {
      border-right-color: $wlhp-main-color-blue;
      background-color: $wlhp-main-color-blue;
    }

    .dunedin & {
      border-right-color: $dunedin-main-color-blue;
      background-color: $dunedin-main-color-blue;
    }

    .loretto & {
      border-right-color: $loretto-main-color-blue;
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      border-right-color: $barony-primary;
      background-color: $barony-primary;
    }
    .yourplace & {
      border-right-color: $yourplace-primary;
      background-color: $yourplace-primary;
    }
    .wheatley & {
      border-right-color: $wheatley-primary;
      background-color: $wheatley-primary;
    }
    .lowther & {
      background: transparent;
      border-right: 0;
      padding: 0.75em 1em 1.5em 1.5em;
      color: #262626;
      font-weight: bold;
    }

    &:before {
      content: '';
      position: absolute;
      right: -42px;
      top: 0;
      border-style: solid;
      border-color: $brand-primary transparent transparent $brand-primary;
      border-width: 21px;
      z-index: 1;
      box-shadow: -3px 0 0 0 $brand-primary;

      .gha-cube & {
        border-color: $cube-main-color-green transparent transparent $cube-main-color-green;
        box-shadow: -3px 0 0 0 $cube-main-color-green;
      }
      .gha-wlhp & {
        border-color: $wlhp-main-color-blue transparent transparent $wlhp-main-color-blue;
        box-shadow: -3px 0 0 0 $wlhp-main-color-blue;
      }
      .dunedin & {
        border-color: $dunedin-main-color-blue transparent transparent $dunedin-main-color-blue;
        box-shadow: -3px 0 0 0 $dunedin-main-color-blue;
      }

      .loretto & {
        border-color: $loretto-main-color-blue transparent transparent $loretto-main-color-blue;
        box-shadow: -3px 0 0 0 $loretto-main-color-blue;
      }
      .barony & {
        border-color: $barony-primary transparent transparent $barony-primary;
        box-shadow: -3px 0 0 0 $barony-primary;
      }
      .yourplace & {
        border-color: $yourplace-primary transparent transparent $yourplace-primary;
        box-shadow: -3px 0 0 0 $yourplace-primary;
      }
      .wheatley & {
        border-color: $wheatley-primary transparent transparent $wheatley-primary;
        box-shadow: -3px 0 0 0 $wheatley-primary;
      }
      .lowther & {
        background: url('{{file_dest}}/lowther-related-before.svg') left center no-repeat;
        left:0;
        position:absolute;
        border:0;
        box-shadow:0;
        width:20px;
        height:53px;
        box-shadow:none;
      }
    }

    &:after {
      border-radius: 0 2px 0 0;
      content: '';
      position: absolute;
      right: -54px;
      top: 0;
      border-style: solid;
      border-color: $brand-primary transparent transparent $brand-primary;
      border-width: 21px;
      box-shadow: -3px 0 0 0 $brand-primary;

      .gha-cube & {
        border-color: $cube-main-color-green transparent transparent $cube-main-color-green;
        box-shadow: -3px 0 0 0 $cube-main-color-green;
      }
      .gha-wlhp & {
        border-color: $wlhp-main-color-blue transparent transparent $wlhp-main-color-blue;
        box-shadow: -3px 0 0 0 $wlhp-main-color-blue;
      }
      .dunedin & {
        border-color: $dunedin-main-color-blue transparent transparent $dunedin-main-color-blue;
        box-shadow: -3px 0 0 0 $dunedin-main-color-blue;
      }

      .loretto & {
        border-color: $loretto-main-color-blue transparent transparent $loretto-main-color-blue;
        box-shadow: -3px 0 0 0 $loretto-main-color-blue;
      }
      .barony & {
        border-color: $barony-primary transparent transparent $barony-primary;
        box-shadow: -3px 0 0 0 $barony-primary;
      }
      .yourplace & {
        border-color: $yourplace-primary transparent transparent $yourplace-primary;
        box-shadow: -3px 0 0 0 $yourplace-primary;
      }
      .wheatley & {
        border-color: $wheatley-primary transparent transparent $wheatley-primary;
        box-shadow: -3px 0 0 0 $wheatley-primary;
      }
      .lowther & {
        background: url('{{file_dest}}/lowther-related-after.svg') right center no-repeat;
        right:calc(100% - 250px);
        position:absolute;
        border:0;
        box-shadow:0;
        width:20px;
        height:53px;
        box-shadow:none;
      }
    }
  }

  li {
    margin-bottom: 1em;
  }
}
