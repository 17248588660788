/* Module: S */
.wheatley-top {
  margin-top: -1.32em;
  position: relative;
  //height: 0;
  //padding-top: 28.5%;
  width: 100%;
  height: 570px;
  @media (max-width: 767px) {
    height: 200px;
  }
  background-color: $wheatley-light-bg;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    margin-bottom: 60px;
    min-height: 370px;
    background-position: 70%;
    &:after {
      position: absolute;
      background-color: $wheatley-light-bg;
      content: " ";
      left: 0;
      right: 0;
      top: 370px;
      bottom: -60px;
    }
  }
  .wheatleycarousel {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }
    min-height: 570px;
    margin: 0px;
    @media (max-width: 767px) {
      display: none;
    }
    .a-slide {
      height: 570px;
      width: 100%;
      background-size: cover !important;
      @media (max-width: 1024px) {
        background-position: 50% center;
      }
    }
  }
  .mobilebanner {
    @media (max-width: 767px) {
      display: block;
    }
    display: none;
  }

  .backsvg {
    position: absolute;
    bottom: 0;
    right: calc(50% - 135px);
    //z-index: 1;
    width: 945px;
    height: 308px;
    .ribbonone {
      fill: #fff;
      opacity: 0.1;

    }
    .ribbontwo {
      fill: #fff;
      opacity: 0.2;

    }
  }
  &__content {
    z-index: 1;
    position: absolute;
    top: 80px;
    @media (max-width: 767px) {
      top: auto;
      bottom: -40px;
      background-color: white;
      left: 15px;
      right: 15px;
      text-align: center;
      padding-bottom: 15px;
    }
    h1 {
      font-size: 42pt;
      color: white;
      font-weight: normal;
      @media (max-width: 767px) {
        font-size: 32px;
        color: $wheatley-secondary;
      }
    }
    .noguttermobile {
      @media (max-width: 767px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}