/* Module: S */
.yourplace-icons {
  padding: 30px 0px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  .lowther & {
    background-color:#f6f7f7;
  }
  h2 {
    font-size: 45px;
    color: $yourplace-secondary;
    .lowther & {
      color: $font-grey;
      font-size: 42px;
      font-weight: 200;
    }
  }
  a {
    .lowther & {
      color: $base-font-colour;
    }
    figure {
      padding-top: 50px;
      width: 100%;
      svg {
        width: 125px;
        .icon_circle {
          fill: #20BFE6;
          .lowther & {
            fill: $lowther-primary;
          }
        }
      }
      figcaption {
        font-size: 28px;
        text-align: center;
        padding-top: 20px;
        p {
          color: $font-grey;
          font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
          .lowther & {
            color: $base-font-colour;
            font-family:"FSMe", "Helvetica Neue", Helvetica, Arial, sans-serif;
          }
        }
      }
    }
    &:hover {
      //text-decoration: none;
      color: $font-grey;
      .lowther & {
        color: $base-font-colour;
      }
    }
  }

}