/* Module: S */
// Pills
// -------------------------

//== Pills
$nav-pills-border-radius: 0;
$nav-pills-active-link-hover-bg: $brand-primary;
$nav-pills-active-link-hover-color: $white;

// The reason this CSS rule is in this module is because it's related to the header.
// The :after on Lowther's .main-navigation extends beyond the width of the page and this hides it.
.lowther, .wheatley {
  .page-wrapper {
    overflow: hidden;
  }
}

.header {
  padding: 25px 0 18px;
  background: #F6F6F6;
  .lowther & {
    padding: 36px 0 18px;
  }
  border-bottom: 2px solid #dddddd;
  position: relative;
  .lowther &, .wheatley & {
    background-color: white;
    border-bottom-width: 0;
    padding-bottom: 0;
  }

  img {
    margin-bottom: 0;
  }
  .sq-header-logo {
    margin-bottom: 1em;
    .lowther & {
      margin-bottom: 27px;
    }
    //.wheatley & {
    //  @media (max-width: 991px) {
    //    width: 150px;
    //  }
    //}
    svg {
      &.lorettologo {
        width: 145px;
        @media (max-width: 991px) {
          width: 130px;
        }
      }
      &.wheatleylogo {
        @media (max-width: 991px) {
          width: 150px;
        }
      }
    }

    ul {
      list-style: none;
      li {
        max-width: 45px;
        display: inline-block;
        padding: 0.3em 10px;
        height: 35px;
        float: left;
        &:first-child {
          margin-left: 0;
        }
        &:nth-child(2) {
          padding-right: 8px;
          .lowther & {
            border-right: 1px solid #333333;
            padding-right: 14px;
          }
        }
        &:nth-child(3) {
          border-right: 1px solid #333333;
          padding-right: 14px;
          .lowther & {
            border-right: 0;
            padding-right: 0;
          }
        }
        &:nth-child(4) {
          padding: 0.3em 0.5em;
        }
      }
    }
  }

  &__button {
    a:hover {
      text-decoration: none;
    }
    a:focus {
      text-decoration: none;
    }
    svg {
      height: 22px;
      width: auto;
      fill: #333333;
      vertical-align: bottom;
      &:hover {
        fill: $brand-primary;

        .gha-cube & {
          fill: $cube-main-color-green;
        }

        .gha-wlhp & {
          fill: $wlhp-main-color-blue;
        }
        .dunedin & {
          fill: $dunedin-main-color-blue;
        }
        .loretto & {
          fill: $loretto-main-color-blue;
        }
        .barony & {
          fill: $barony-primary;
        }
        .yourplace & {
          fill: $yourplace-primary;
        }
        .lowther & {
          fill: $lowther-primary;
        }
        .wheatley & {
          fill: $wheatley-primary;
        }
      }
    }
    img {
      height: 22px;
      width: auto;
    }
  }
  &__logo {
    height: 72px;
    .logoback {
      fill: $brand-primary;

      .gha-cube & {
        fill: $cube-main-color-green;
      }
      .gha-wlhp & {
        fill: $wlhp-main-color-blue;
      }
      .dunedin & {
        fill: $dunedin-main-color-blue;
      }
      .loretto & {
        fill: $loretto-main-color-blue;
      }
      .barony & {
        fill: $barony-primary;
      }
      .yourplace & {
        fill: $yourplace-primary;
      }
      .lowther & {
        fill: $lowther-primary;
      }
    }
    .logofront {
      fill: $white;
    }
    .loretto & {
      @media (max-width: 768px) {
        margin-left: -34px;
      }
    }
  }

}

.main-navigation {
  &__sub-nav-wrapper {
    display: none;
    position: absolute;
    top: 100%;
    background-color: $dark-red;
    width: 100%;
    left: 0;
    z-index: 5;
    padding: 35px 0 35px;

    .gha-cube & {
      background-color: $cube-main-color-green;
    }

    .gha-wlhp & {
      background-color: $wlhp-main-color-blue;
    }
    .dunedin & {
      background-color: $dunedin-main-color-blue;
    }
    .loretto & {
      background-color: $loretto-main-color-darkblue;
    }
    .barony & {
      background-color: $barony-primary;
    }
    .yourplace & {
      background-color: $yourplace-secondary;
    }
    .lowther & {
      background-color: black;
    }
    .wheatley & {
      background-color: $wheatley-primary;
    }
    a:hover {
      color: #ffffff;
    }
    a:focus {
      color: #ffffff;
    }
  }

  &__sub-nav-column-wrapper {
    display: table;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__sub-nav-column {
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
    display: table-cell;
    &.sixcolumn {
      width: 16.66666%;
    }
    &.sevencolumn {
      width: 14.28571%;
    }
    .wheatley & {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .gha-wlhp &__sub-header {
    color: white;
  }
  .dunedin &__sub-header {
    color: white;
  }

  .login {
    margin-right: 20px;
    background-color: $brand-primary;

    .gha-cube & {
      background-color: $cube-main-color-green;
    }

    .gha-wlhp & {
      background-color: $wlhp-main-color-blue;
    }
    .dunedin & {
      background-color: $dunedin-main-color-blue;
    }
    .loretto & {
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      background-color: $barony-primary;
    }
    .lowther & {
      background-color: $lowther-primary;
      border-radius: 0;
      margin-right: 0px;
    }

    position: relative;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    .yourplace &, lowther & {
      margin-right: 0px;
      background-color: $yourplace-primary;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    &.main-navigation__first-level > a {
      color: $white;
      .dunedin &, .barony & {
        color: $black;
      }
      &:hover {
        .dunedin &, .lowther &, .barony & {
          color: $white;
        }
      }
    }

    > a {
      color: $white;
      background: transparent;

      &:hover
        //,:focus
      {
        color: $white;
        //background: transparent;
      }

      &:before,
      &:after {
        box-shadow: -3px 0 0 0 $brand-primary;
        content: '';
        position: absolute;
        right: -10px;

        .gha-cube & {
          box-shadow: -3px 0 0 0 $cube-main-color-red;
        }

        .gha-wlhp & {
          box-shadow: -3px 0 0 0 $wlhp-main-color-purple;
        }
        .dunedin & {
          box-shadow: -3px 0 0 0 $dunedin-main-color-yellow;
        }
        .loretto & {
          box-shadow: -3px 0 0 0 $loretto-main-color-blue;
        }
        .barony & {
          box-shadow: -3px 0 0 0 $barony-secondary;
        }
        .yourplace & {
          box-shadow: 0 0 0 0 $yourplace-primary;
        }
        .lowther & {
          box-shadow: 0 0 0 0 $lowther-primary;
        }
      }

      &:before {
        background: $brand-primary;
        bottom: 10px;
        top: 0;
        width: 10px;
        border-top-right-radius: 3px;

        .gha-cube & {
          background: $cube-main-color-red;
        }

        .gha-wlhp & {
          background: $wlhp-main-color-purple;
        }
        .dunedin & {
          background: $dunedin-main-color-yellow;
        }
        .loretto & {
          background: $loretto-main-color-blue;
        }
        .barony & {
          background: $barony-secondary;
        }
        .yourplace & {
          background: none;
          //background: $yourplace-primary;
        }
        .lowther & {
          background: none;
        }
      }

      &:after {
        bottom: -2px;
        box-shadow: -4px 0 0 0 $brand-primary, 0 -4px 0 0 $brand-primary, -4px -4px 0 0 $brand-primary;
        border-color: $brand-primary transparent transparent $brand-primary;
        border-style: solid;
        border-width: 5px;

        .gha-cube & {
          box-shadow: -4px 0 0 0 $cube-main-color-red, 0 -4px 0 0 $cube-main-color-red, -4px -4px 0 0 $cube-main-color-red;
          border-color: $cube-main-color-red transparent transparent $cube-main-color-red;
        }

        .gha-wlhp & {
          box-shadow: -4px 0 0 0 $wlhp-main-color-purple, 0 -4px 0 0 $wlhp-main-color-purple, -4px -4px 0 0 $wlhp-main-color-purple;
          border-color: $wlhp-main-color-purple transparent transparent $wlhp-main-color-purple;
        }
        .dunedin & {
          box-shadow: -4px 0 0 0 $dunedin-main-color-yellow, 0 -4px 0 0 $dunedin-main-color-yellow, -4px -4px 0 0 $dunedin-main-color-yellow;
          border-color: $dunedin-main-color-yellow transparent transparent $dunedin-main-color-yellow;
        }
        .loretto & {
          box-shadow: -4px 0 0 0 $loretto-main-color-blue, 0 -4px 0 0 $loretto-main-color-blue, -4px -4px 0 0 $loretto-main-color-blue;
          border-color: $loretto-main-color-blue transparent transparent $loretto-main-color-blue;
        }
        .barony & {
          box-shadow: -4px 0 0 0 $barony-secondary, 0 -4px 0 0 $barony-secondary, -4px -4px 0 0 $barony-secondary;
          border-color: $barony-secondary transparent transparent $barony-secondary;
        }
        .yourplace & {
          box-shadow: 0 0 0 0 $yourplace-primary, 0 0 0 0 $yourplace-primary, 0 0 0 0 $yourplace-primary;
          border-color: transparent; //$yourplace-primary transparent transparent $yourplace-primary;
        }
        .lowther & {
          box-shadow: 0 0 0 0 $lowther-primary, 0 0 0 0 $lowther-primary, 0 0 0 0 $lowther-primary;
          border-color: transparent;
        }
      }
    }
  }

  .nav {
    .lowther & {
      height: 65px;
      @media (max-width: 991px) {
        height: 0px;
      }
    }
    .wheatley & {
      height: 65px;
      //background-color: $wheatley-light-bg;
      @media (max-width: 991px) {
        height: 0px;
      }
    }
    > li {
      a:hover,
      a:focus {
        .lowther & {
          background-color: $black;
        }
      }
      > a {
        .lowther & {
          padding-top: 22px !important;
          padding-bottom: 23px !important;
        }
        .wheatley & {
          padding-top: 22px !important;
          padding-bottom: 23px !important;
        }
      }
    }
    > li:last-child {
      background: $brand-primary;

      .gha-cube & {
        background-color: $cube-main-color-red;
      }

      .gha-wlhp & {
        background-color: $wlhp-main-color-purple;
      }
      .dunedin & {
        background-color: $dunedin-main-color-yellow;
      }
      .loretto & {
        background-color: $loretto-main-color-blue;
      }
      .barony & {
        background-color: $barony-secondary;
      }
      .yourplace & {
        background-color: $yourplace-primary;
        a {
          color: white;
        }
      }
      .lowther & {
        background-color: $lowther-primary;
        height: 65px;
        a {
          color: black;
        }
      }
      .wheatley & {
        background-color: transparent;
      }

      > a:hover,
      > a:focus {
        background: $brand-primary;

        .gha-cube & {
          background-color: $cube-main-color-red;
        }

        .gha-wlhp & {
          background-color: $wlhp-main-color-purple;
        }
        .dunedin & {
          background-color: $dunedin-main-color-yellow;
        }
        .loretto & {
          background-color: $loretto-main-color-blue;
        }
        .barony & {
          background-color: $barony-secondary;
        }
        .yourplace & {
          background-color: $yourplace-primary;
        }
        .lowther & {
          background-color: $lowther-primary;
        }
        .wheatley & {
          background-color: transparent;
        }
      }
      &.main-nav-hover {
        .lowther & {
          a {
            color: white;
          }
        }
      }
    }

    // The Lowther main nav has a unique style and there are additional CSS rules for .main-navigation and .main-navigation__sub-nav-wrapper to acheive the effect.
    .lowther & {
      background: #444;
    }
    .wheatley & {
      background: $wheatley-light-bg;
    }

  }
  // The Lowther main nav background colour extends the width of the page but it is inside a fixed width container. The following CSS achieves the effect.
  .lowther & {
    position: relative;
    &:before,
    &:after {
      background: #444;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100vw;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
  .wheatley & {
    position: relative;
    &:before,
    &:after {
      background: $wheatley-light-bg;
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100vw;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }

  li.main-navigation__first-level {
    position: static;
    height: 42px;
    float: left;

    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
      color: $black;
      font-weight: bold;
      .yourplace & {
        color: $yourplace-secondary;
        font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      .lowther & {
        color: white;
        font-wheight: 200;
      }
      .wheatley & {
        font-weight: 200;
      }

      &:hover
        //,&:focus
      {
        //background-color: $brand-primary;
        //color: $white;

      }

    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      //new version with pills
      //color: $white;
      //background-color: $nav-pills-active-link-hover-bg;
      //@include sq-border-radius(3px);

      //old version underlined
      color: $nav-pills-active-link-hover-bg;
      border-bottom: 2px solid $nav-pills-active-link-hover-bg;

      .gha-cube & {
        color: $cube-main-color-green;
        border-bottom-color: $cube-main-color-green;
      }

      .gha-wlhp & {
        color: $wlhp-main-color-blue;
        border-bottom-color: $wlhp-main-color-blue;
      }
      .dunedin & {
        color: $dunedin-main-color-blue;
        border-bottom-color: $dunedin-main-color-blue;
      }
      .loretto & {
        color: $loretto-main-color-darkblue;
        border-bottom-color: $loretto-main-color-darkblue;
      }
      .barony & {
        color: $barony-primary;
        border-bottom-color: $barony-primary;
      }
      .yourplace & {
        color: $yourplace-primary;
        border-bottom-color: $yourplace-primary;
      }
      .lowther & {
        color: white;
        border-bottom: none;
        background-color: black;
      }
      .wheatley & {
        color: black;
        border-bottom: 0px;
        &:after {
          bottom: 0%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: #572932;
          border-width: 10px;
          margin-left: -10px;
          box-shadow: none;
        }
      }

      &:hover
        //,&:focus
      {
        //old version underline
        //color: $white;
        border-bottom: 2px solid $nav-pills-active-link-hover-bg;

        .gha-cube & {
          border-bottom-color: $cube-main-color-green;
        }

        .gha-wlhp & {
          border-bottom-color: $wlhp-main-color-blue;
        }
        .dunedin & {
          border-bottom-color: $dunedin-main-color-blue;
        }
        .loretto & {
          border-bottom-color: $loretto-main-color-darkblue;
        }
        .barony & {
          border-bottom-color: $barony-primary;
        }
        .yourplace & {
          border-bottom-color: $yourplace-primary;
        }
        .lowther & {
          border-bottom-color: $lowther-primary;
          border: 0;
        }
        .wheatley & {
          border: 0;
        }
      }
    }

    // &:hover, &.active:hover, {
    &.active.main-nav-hover,
    &.main-nav-hover {
      > a {
        background: $dark-red !important;
        border-bottom: 22px solid $dark-red;
        color: #fff;
        border-radius: 3px 3px 0 0;

        .gha-cube & {
          background: $cube-main-color-green !important;
          border-bottom-color: $cube-main-color-green;
        }

        .gha-wlhp & {
          background: $wlhp-main-color-blue !important;
          border-bottom-color: $wlhp-main-color-blue;
        }
        .dunedin & {
          background: $dunedin-main-color-blue !important;
          border-bottom-color: $dunedin-main-color-blue;
        }
        .loretto & {
          background: $loretto-main-color-darkblue !important;
          border-bottom-color: $loretto-main-color-darkblue;
        }
        .barony & {
          background: $barony-primary !important;
          border-bottom-color: $barony-primary;
        }
        .yourplace & {
          background: $yourplace-secondary !important;
          border-bottom-color: $yourplace-secondary;
          font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        .lowther & {
          background: black !important;
          border-bottom-color: black;
        }
        .wheatley & {
          background: transparent !important;
          border-bottom: none;
          position: relative;
          color: black;
          &:after {
            bottom: 0%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: #572932;
            border-width: 10px;
            margin-left: -10px;
            box-shadow: none;
          }
        }
      }

      .main-navigation__sub-nav-wrapper {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        &:before {
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          bottom: -1px;
        }
        &:after {
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          bottom: -1px;
        }
      }
      &.nosubnav {
        > a {
          background: $dark-red !important;
          border-bottom: 9px solid $dark-red;
          color: #fff;
          //text-decoration: underline;
          text-decoration: none;
          border-radius: 3px;
          .gha-cube & {
            background: $cube-main-color-green !important;
            border-bottom: 9px solid $cube-main-color-green;
          }

          .gha-wlhp & {
            background: $wlhp-main-color-blue !important;
            border-bottom: 9px solid $wlhp-main-color-blue;
          }
          .dunedin & {
            background: $dunedin-main-color-blue !important;
            border-bottom: 9px solid $dunedin-main-color-blue;
          }
          .loretto & {
            background: $loretto-main-color-darkblue !important;
            border-bottom: 9px solid $loretto-main-color-darkblue;
          }
          .barony & {
            background: $barony-primary !important;
            border-bottom: 9px solid $barony-primary;
          }
          .yourplace & {
            background: $yourplace-secondary !important;
            border-bottom: 9px solid $yourplace-secondary;
          }
          .lowther & {
            background: black !important;
            border-bottom: none;
          }
          .wheatley & {
            color: black;
            background: transparent !important;
            border-bottom: none;
          }
        }
        .main-navigation__sub-nav-wrapper {
          display: none;
        }
      }
      > a {
        &:before {
          box-shadow: -1px 0 0 0 $dark-red;
          bottom: -20px;
          background: $dark-red;

          .gha-cube & {
            box-shadow: -1px 0 0 0 $cube-main-color-green;
            background: $cube-main-color-green;
          }

          .gha-wlhp & {
            box-shadow: -1px 0 0 0 $wlhp-main-color-blue;
            background: $wlhp-main-color-blue;
          }
          .dunedin & {
            box-shadow: -1px 0 0 0 $dunedin-main-color-blue;
            background: $dunedin-main-color-blue;
          }
          .loretto & {
            box-shadow: -1px 0 0 0 $loretto-main-color-darkblue;
            background: $loretto-main-color-darkblue;
          }
          .barony & {
            box-shadow: -1px 0 0 0 $barony-primary;
            background: $barony-primary;
          }
          .yourplace & {
            box-shadow: 0 0 0 0 $yourplace-secondary;
            background: transparent;
          }
          .lowther & {
            box-shadow: 0 0 0 0 $lowther-primary;
            background: transparent;
          }
        }

        &:after {
          box-shadow: -1px -1px 0 1px $dark-red;
          border-color: $dark-red transparent transparent $dark-red;

          .gha-cube & {
            box-shadow: -1px -1px 0 1px $cube-main-color-green;
            border-color: $cube-main-color-green transparent transparent $cube-main-color-green;
          }

          .gha-wlhp & {
            box-shadow: -1px -1px 0 1px $wlhp-main-color-blue;
            border-color: $wlhp-main-color-blue transparent transparent $wlhp-main-color-blue;
          }
          .dunedin & {
            box-shadow: -1px -1px 0 1px $dunedin-main-color-blue;
            border-color: $dunedin-main-color-blue transparent transparent $dunedin-main-color-blue;
          }
          .loretto & {
            box-shadow: -1px -1px 0 1px $loretto-main-color-darkblue;
            border-color: $loretto-main-color-darkblue transparent transparent $loretto-main-color-darkblue;
          }
          .barony & {
            box-shadow: -1px -1px 0 1px $barony-primary;
            border-color: $barony-primary transparent transparent $barony-primary;
          }
          .yourplace & {
            box-shadow: 0 0 0 0 $yourplace-secondary;
            border-color: transparent;
          }
          .lowther & {
            box-shadow: 0 0 0 0 $lowther-primary;
            border-color: transparent;
          }
        }
      }
    }

    &.login {

      > a {
        //&:before {
        //  box-shadow: -1px 0 0 0 $dark-red;
        //  bottom: -20px;
        //  background: $dark-red;
        //}
        //
        //&:after {
        //  box-shadow: -1px -1px 0 1px $dark-red;
        //  border-color: $dark-red transparent transparent $dark-red;
        //}
      }

      &:hover {

      }
    }
  }

  &__sub-nav-wrapper {

    // The Lowther main nav background colour extends the width of the page so position relative was used on .main-navigation to achieve the effect.
    // This prevents the .main-navigation__sub-nav-wrapper element from extending full width, so the additional CSS below resolves this issue.
    .lowther & {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      &:before,
      &:after {
        background: $black;
        //bottom: 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100vw;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        bottom: -1px;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }
    .wheatley & {
      border-bottom: 0px ;
      &:before,
      &:after {
        background: $wheatley-primary;
        //bottom: 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100vw;
        border-bottom: 0px;
        bottom: -1px;
      }
      &:before {
        right: 100%;
      }
      &:after {
        left: 100%;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      float: none;
      margin-top: 20px;
      .wheatley & {
        margin-top: 13px;
      }
    }

    a {
      color: #fff;
      //background: none;
      font-weight: 200;
      .wheatley & {
        padding-bottom: 10px;
        display: block;
        width: 100%;
        border-bottom: 1px dotted white;
      }
    }

    .main-navigation__second-level-link {
      font-weight: bold;
      font-size: 1.125em;
      .yourplace & {
        font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
      }
      .wheatley & {
        text-transform: uppercase;
        font-size: 1em;
        display: block;
        padding-bottom: 10px;
        width: 100%;
        border-bottom: 2px solid $wheatley-yellow;
      }
    }
  }

  &__second-level-link {
    font-weight: bold;
  }
}

.login {

  &-panel {
    //padding: 30px 0;
    padding: 0;
    display: block;

    img {
      max-width: 276px;
      width: 100%;
      padding: 0;
      padding-top: 22px;
    }

    li {
      margin-top: 12px;
    }

    h2,
    p {
      color: $white;
      font-weight: 100;
      line-height: 1.3;
    }

    h2 {
      font-size: 2rem;
      padding-bottom: 5px;
      margin-top: 0;
      max-width: 350px;
      .lowther & {
        color: $lowther-primary;
      }
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 20px;
      max-width: 290px;
    }

    .button {
      &:focus {
        // background-color: $nav-pills-active-link-hover-bg;
        color: $white;

        // .gha-cube & {
        //   background-color: $cube-main-color-green;
        // }
        //
        // .gha-wlhp & {
        //   background-color: $wlhp-main-color-blue;
        // }
      }
    }

    &__register {
      padding-left: 40px;
    }

    &__lognow {
      padding-left: 40px;

      .list-arrow {
        padding-bottom: 20px;
      }
    }
    a.button {
      font-weight: bold;
      &:hover {
        .lowther & {
          background-color: $lowther-primary !important;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .login {
    &-panel {
      img {
        max-width: 250px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login {
    &-panel {
      img {
        display: none;
      }
    }

    .main-navigation__sub-nav-wrapper {
      padding-top: 5px;

      br {
        display: none;
      }

      .login-panel {
        .button {
          width: 145px;
          margin-top: 0;
        }

        &__lognow {
          padding-right: 10px;
          padding-left: 0;
          margin: 0 15px;
          width: calc(100% - 30px);
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .header {
    .sq-header-logo {
      .header__mobile-nav-btn {
        display: none;
      }
    }
  }

  .login {
    &-panel {
      &__lognow {
        border-right: 1px solid rgba($white, 0.4);
      }
    }
  }
}

@media (max-width: 991px) {
  .header {
    padding: 15px 0 9px;
    .loretto & {
      padding: 20px 0 28px;
    }

    .main-navigation {
      margin: 0 -15px;
    }

    .main-navigation__first-level:not(.login) {
      display: none;
    }

    .login {
      > a,
      &:after,
      &:before {
        display: none;
      }
    }

    .main-navigation li.main-navigation__first-level {
      height: auto;
    }

    .main-navigation__sub-nav-wrapper {
      display: block;
    }

    .sq-header-logo {
      margin-bottom: 0;
    }

    &__logo {
      //height: 42px;
    }
  }

  .main-navigation li.main-navigation__first-level + li {
    margin: 20px 0 0;
  }

  li.login {
    display: none;
    width: 100%;

    > div {
      position: relative;
    }

    .login-panel {
      display: block;
      padding: 0;

      li {
        margin-top: 20px;
      }

      .button {
        width: 100%;
        text-align: center;
      }

      &__register {
        padding: 30px 10px 0 0;
      }

      &__lognow {
        border-bottom: 1px solid rgba($white, 0.7);
        padding: 10px 25px 0 15px;
        padding-bottom: 30px;

        a {
          margin-top: 20px;
        }
      }
    }
  }
}
