/* Module: S */
.yourplace-services-3d {
  padding: 30px 0px;
  text-align: center;
  h2 {
    font-size: 45px;
    color: $yourplace-secondary;
    margin-bottom: 20px;
  }
  .servicestiles {
    $boxwidth: 300px;
    $boxheight: 300px;
    .box-rotate-wrap {
      float: left;
      perspective: 1000px;
      padding: 3px;
      &:nth-child(odd) {
        .box {
          .front {
            background-color: $yourplace-primary;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .left {
            background-color: $yourplace-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &:nth-child(even) {
        .box {
          .front {
            background-color: $yourplace-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .left {
            background-color: $yourplace-primary;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .box, .box > div {
      width: $boxwidth;
      height: $boxheight;
    }

    .box-rotate {
      position: relative;
      transition: all 0.8s ease;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, -$boxheight/2);
    }
    .box-rotate > div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: #fff;
      padding: 20px;
      overflow: auto;
    }
    .box-rotate img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .box-rotate .front {
      background: $yourplace-secondary;
      z-index: 2;
      transform: rotate3d(0, 1, 0, 0deg) translate3d(0, 0, $boxwidth/2);
    }
    .box-rotate .back {
      background: orange;
      transform: rotate3d(0, 1, 0, 180deg) rotate(180deg) translate3d(0, 0, $boxwidth/2);
    }
    .box-rotate .top {
      background: purple;
      height: $boxwidth;
      transform: rotate3d(1, 0, 0, 90deg) translate3d(0, 0, $boxwidth/2);
    }
    .box-rotate .bottom {
      background: blue;
      height: $boxwidth;
      transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, $boxheight - $boxwidth/2);
    }
    .box-rotate .left {
      background: $yourplace-primary;
      transform: rotate3d(0, 1, 0, -90deg) translate3d(0, 0, $boxwidth/2);
    }
    .box-rotate .right {
      background: green;
      transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, $boxwidth/2);
    }

    .box-rotate-top:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, -90deg);
    }
    .box-rotate-bottom:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, 90deg);
    }
    .box-rotate-left:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(0, 1, 0, 90deg);
    }
    .box-rotate-right:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(0, 1, 0, -90deg);
    }
    .box-rotate-back:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, -180deg);
    }
    .box-rotate-gyro .back {
      width: $boxheight;
      height: $boxwidth;
      transform: rotate3d(0, 1, 0, 180deg) rotate(90deg) translate3d($boxheight/2 - $boxwidth/2, -$boxheight/2 + $boxwidth/2, $boxwidth/2);
    }
    .box-rotate-gyro:hover {
      transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 1, 0, -180deg);
    }

    .box.box-blend div {
      background: #fff;
      box-shadow: 0 0 1px rgba(0,0,0,.8);
    }
  }
}