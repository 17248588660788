/* Module: S */
.lowther-featured-content {
  padding: 60px 0;
  .lowther-howitworks__intro {
    padding: 0 0 30px 0;
    h2 {
      font-weight: normal;
    }
    p {
      font-size: 22px;
      color: $font-grey;
      width: 80%;
    }
  }
  .content_column {
    column-count: 2;
    @media (max-width: 767px) {
      column-count: 1;
    }
  }
}