/* Module: S */
.landing-page-boxes {
  margin: 0 -5px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.landing-page-box {
  width: calc(25% - 10px);
}

.landing-page-box__wide {
  width: calc(33% - 10px);
}

.landing-page-box, .landing-page-box__wide {
  background: #f8f8f8;
  margin: 0 5px  0.5em;
  border-bottom: solid #e4e4e4 3px;
  padding: 20px 10px 35px;
  .wheatley & {
    background: $wheatley-medium-bg;
    border-bottom: 0px;
    h2 {
      font-size: 26px;
      color: $base-font-colour;
      font-weight: bold;
    }
    .linebottom {
      border-bottom: 1px dotted $wheatley-secondary;
      padding-bottom: 0.9em;
      margin-bottom: 1em;
    }
  }

  h2 {
    font-size: 1.5em;
    margin: 0;
    color: #383838;
    font-weight: 200;

    a,
    .gha-cube & a,
    .gha-wlhp & a,
    .dunedin & a,
    .loretto & a,
    .barony & a,
    .yourplace & {
      color: #383838;
      // &:hover,
      // &:focus {
      //   //color: #9b0000;
      // }
    }
    .lowther & {
      a {
        font-weight: 200;
      }
    }

  }

  ul {
    margin: 0;
    font-weight: 200;
    //.lowther & {
    //  font-weight: normal;
    //}
  }

  li, p {
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: 200;
    //.lowther & {
    //  font-weight: normal;
    //}
  }

  p {
    color: $font-grey;
    font-weight: 200;

    .gha-cube &,
    .gha-wlhp &,
    .dunedin &,
    .loretto &,
    .barony &,
    .yourplace & {
      color: $font-grey;
    }
    .lowther & {
      color: $lowther-font-tile;
      //font-weight: normal;
    }
  }
}



@media (max-width: 767px) {
  .landing-page-box, .landing-page-box__wide {
    width: 100%;
  }
}
