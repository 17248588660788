/* Module: S */
.yourplace-accordion-tiles {

  padding: 20px 0px 60px 0;
  text-align: left;
  border-bottom: 1px solid #ccc;
  h1 {
    font-size: 2.25em;
    font-weight: 700;
    color: $yourplace-secondary;
  }
  h2 {
    color: #737373;
    font-size: 1.5em;
    line-height: 1.9375rem;
    font-weight: 200;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .accordion-title {
    h2 {
      margin-bottom: 0px;
    }
  }
  .row-eq-height {
    display: flex;
    //display: -webkit-box;
    ////display: -webkit-flex;
    //display: -ms-flexbox;
    margin-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    @media (max-width: 991px) {
      display: block;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 0px;
    }
  }
  .smallexpanded {
    display: none;
    padding: 70px 30px 35px 30px;
    background-color: $yourplace-secondary;
    text-align: left;
    color: white;
    position: relative;
    margin-top: -20px;
    .tile_expanded__close {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 5;
      @media (max-width: 991px) {
        right: 10px;
        top: 40px;
      }

      &:before, &:after {
        position: absolute;
        content: '';
        right: 12px;
        top: -3px;
        width: 3px;
        height: 20px;
        background-color: #fff;
      }
      &:before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
    h3 {
      font-size: 24px;
      color: $yourplace-primary;
    }
    .button {
      background-color: $yourplace-secondary;
    }
    .mobileicon {
      width: 100%;
      margin: 5px 0;
      padding: 5px 85px;
      background: url("mysource_files/mobile_icon.png") 2% center / 60px 60px no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      resize: vertical;
      p {
        margin: 0;
        text-align: left;
      }
    }
  }
  //&__topline {
  //  border-top: 1px solid $yourplace-secondary;
  //}
  .outeryourplacetiles {
    //flex-grow: 1;
    //flex-shrink: 1;
    //flex-basis: 0px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 15px;
    }
    .yourplacetiles {
      flex-grow: 1;
      padding: 20px;
      background-color: $yourplace-gray-light-bg;
      z-index:1;
      h3 {
        font-size: 24px;
        color: $yourplace-secondary;
        margin: 0px 0px 10px 0px;
      }
      p {
        font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #7A7A7A;
      }
      .button_white {
        color: $yourplace-primary;
        border: 1px solid $yourplace-primary;
        padding: 5px;
      }
      &.active {
        &:after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-top-color: $yourplace-gray-light-bg;
          border-width: 30px;
          margin-left: -30px;
          z-index: 7;
        }
      }
    }

  }
  .expanded {
    margin-top: -20px;
    &.big_close, &.big_open {
      display: none;
      overflow: hidden;
      padding: 80px 0 30px 0;
      background-color: $yourplace-secondary;
      text-align: left;
      color: white;
      .relative {
        position: relative;
        .tile_expanded__close {
          position: absolute;
          right: 5px;
          top: -20px;
          width: 30px;
          height: 30px;
          cursor: pointer;
          z-index: 5;
          @media (max-width: 991px) {
            right: 15px;
            top: 15px;
          }

          &:before, &:after {
            position: absolute;
            content: '';
            right: 12px;
            top: -3px;
            width: 3px;
            height: 20px;
            background-color: #fff;
          }
          &:before {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
      h3 {
        position: relative;
        font-size: 28px;
        margin-top: 0;
        color: $yourplace-primary;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .nopaddingleft {
        padding-left: 0px;
        padding-right: 5px;
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .img-wrapper {
        margin-top: 5px;
        padding-bottom: 100%;
      }
      .button {
        background-color: $yourplace-primary;
        //margin-top: 50px;
      }
    }
    .mobileicon {
      width: 100%;
      margin: 5px 0;
      padding: 5px 85px;
      background: url("mysource_files/mobile_icon.png") 0% center / 60px 60px no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      resize: vertical;
      p {
        margin: 0;
      }
    }
  }
}

