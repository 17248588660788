/* Module: S */
.wheatleyvideo {
  position: relative;
  width: 100%;
  padding-bottom: 28px;

  .backsvg {
    position: absolute;
    top: -210px;
    left: calc(50% - 135px);
    width: 945px;
    height: 308px;
    .ribbonone {
      fill: $wheatley-secondary;
      opacity: 0.4;
    }
    .ribbontwo {
      fill: $wheatley-secondary;
      opacity: 0.3;
    }
  }
  .backsvg2 {
    position: absolute;
    bottom: 0;
    right: calc(60% - 135px);
    //z-index: 1;
    width: 945px;
    height: 308px;
    .ribbonone {
      fill: $wheatley-secondary;
      opacity: 0.4;
    }
    .ribbontwo {
      fill: $wheatley-secondary;
      opacity: 0.3;
    }
  }
  &__content {
    text-align: center;
    p {
      color: $wheatley-primary;
      font-weight: 400;
      &#wheatleyvideobutton {
        cursor: pointer;
        display: inline-block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    .video-widget {
      position: relative;
      background-color: #000;
      margin-bottom: 24px;
      padding-bottom: 46.9%;
      .wheatley-overlay {
        .wheatleyplaybtn {
          top: calc(50% - 87px);
          left: calc(50% - 87px);
          position: absolute;
          content: " ";
          width: 175px;
          height: 175px;
          @media (max-width: 767px) {
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            width: 100px;
            height: 100px;
          }
          .wheatleyplaybtn_play {
            cursor: pointer;
            transition-duration: 0.5s;
            fill: rgba(255, 255, 255, 0.6);
          }
          &:hover, &:focus {
            .wheatleyplaybtn_play {
              fill: rgba(255, 255, 255, 1);
            }
          }
        }
        .video-thumbnail {
          left: 0;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          margin: 0;

        }
      }
    }



  }
}