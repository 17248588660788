/* Main content area general styles */

img {
  border-radius: 0px;
  margin-bottom: 1.75em;
}

.intro {
  font-size: 1.5em;
  line-height: 1.3;
  margin-bottom: 0.75em;
  color: $brand-primary;

  .gha-cube & {
    color: $cube-main-color-green;
  }
  .gha-wlhp & {
    color: $wlhp-main-color-blue;
  }
  .dunedin & {
    color: $dunedin-main-color-blue;
  }
  .loretto & {
    color: $loretto-main-color-blue;
  }
  .barony & {
    color: $barony-primary;
  }
  .yourplace & {
    color: $yourplace-secondary;
  }
  .lowther & {
    color: $lowther-font-grey;
  }
  .wheatley & {
    color: $wheatley-secondary;
    font-size: 30px;
    font-weight: 200;
    line-height: 2.25rem;
    font-family: Brauer, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.main-header {
  border-bottom: 1px solid #ccc;
  color: $font-grey;
  padding-bottom: 10px;

  .gha-cube &, .gha-wlhp &, .dunedin &, .loretto &, .barony &, {
    color: $font-grey;
  }
  .lowther & {
    border-bottom: 4px solid #c4d600;
    color: $base-font-colour;
  }
  .wheatley & {
    border-bottom: 1px dotted $wheatley-secondary;
    color: $wheatley-primary;
    font-size: pxToEm(34);
  }
}

.main {
  background-color: #fff;
  padding-top: 1.32em;
  padding-bottom: 60px;
}

.download-link {
  background: url('{{file_dest}}/download-link.svg') no-repeat 4px 1px / 12px;
  padding-left: 21px;
  display: block;
  font-weight: 200;
  // height: 17px;

  .gha-cube & {
    background-image: url('{{file_dest}}/gha-cube-download-link.svg');
  }
  .gha-wlhp & {
    background-image: url('{{file_dest}}/gha-wlhp-download-link.svg');
  }
  .dunedin & {
    background-image: url('{{file_dest}}/dunedin-download-link.svg');
  }
  .loretto & {
    background-image: url('{{file_dest}}/loretto-download-link.svg');
  }
  .barony & {
    background-image: url('{{file_dest}}/barony-download-link.svg');
  }
  .yourplace & {
    background-image: url('{{file_dest}}/yourplace-download-link.svg');
  }
  .lowther & {
    background-image: url('{{file_dest}}/lowther-download-link.svg');
    //font-weight: normal;
  }
  .wheatley & {
    background-image: url('{{file_dest}}/wheatley-download-link.svg');
  }
  .text-muted {
    font-weight: lighter;
  }
}

.external-link {
  background: url('{{file_dest}}/external-link.svg') no-repeat 4px 2px / 15px;
  padding-left: 27px;
  display: block;
  font-weight: 200;
  // height: 17px;

  .gha-cube & {
    background-image: url('{{file_dest}}/gha-cube-external-link.svg');
  }
  .gha-wlhp & {
    background-image: url('{{file_dest}}/gha-wlhp-external-link.svg');
  }
  .dunedin & {
    background-image: url('{{file_dest}}/dunedin-external-link.svg');
  }
  .loretto & {
    background-image: url('{{file_dest}}/loretto-external-link.svg');
  }
  .barony & {
    background-image: url('{{file_dest}}/barony-external-link.svg');
  }
  .yourplace & {
    background-image: url('{{file_dest}}/yourplace-external-link.svg');
  }
  .lowther & {
    background-image: url('{{file_dest}}/lowther-external-link.svg');
    //font-weight: normal;
  }
  .wheatley & {
    background-image: url('{{file_dest}}/wheatley-external-link.svg');
  }
}

.internal-link {
  background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 8px 2px / 6px;
  padding-left: 27px;
  display: block;
  font-weight: 200;

  .gha-cube & {
    background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
  }
  .gha-wlhp & {
    background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
  }
  .dunedin & {
    background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
  }
  .loretto & {
    background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
  }
  .barony & {
    background-image: url('{{file_dest}}/barony-bullet-arrow.svg');
  }
  .yourplace & {
    background-image: url('{{file_dest}}/yourplace-bullet-arrow.svg');
  }
  .lowther & {
    background: url('{{file_dest}}/lowther-tick.svg') no-repeat center left;
    background-size: 15px 15px;
  }
  .wheatley & {
    background-image: url('{{file_dest}}/wheatley-bullet-arrow.svg');
  }
}

.list-unstyled {
  .gha-wlhp & a {
    color: $wlhp-main-color-purple;
  }
  .dunedin & a {
    color: #002E6B;
  }
  .loretto & a {
    color: $loretto-main-color-darkblue;
  }
  .yourplace & a {
    color: $yourplace-primary;
  }
  .lowther & a {
    color: $lowther-links;
    font-weight: normal;
  }
  .wheatley & a {
    color: $wheatley-primary;
  }
}

.list-arrow {

  ul & {
    padding-left: 11px;
  }

  list-style: none;
  padding-left: 1px;

  li {
    font-weight: 200;
    background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
    // height: 17px;

    .gha-cube & {
      background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
    }
    .gha-wlhp & {
      background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
    }
    .dunedin & {
      background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
    }
    .loretto & {
      background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
    }
    .barony & {
      background-image: url('{{file_dest}}/barony-bullet-arrow.svg');
    }
    .yourplace & {
      background-image: url('{{file_dest}}/yourplace-bullet-arrow.svg');
    }
    .lowther & {
      background: url('{{file_dest}}/lowther-tick.svg') no-repeat center left;
      background-position: 0 3px;
      background-size: 15px 15px;
      padding-left: 20px;
    }
    .wheatley & {
      background-image: url('{{file_dest}}/wheatley-bullet-arrow.svg');
    }
  }
}

.list-arrow_outside {
  ul & {

  }
  margin-top: -9px;
  padding-left: 0px;
  list-style: none;

  li {
    font-weight: 200;
    margin-bottom: 1em;
    background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
    line-height: 1.5;
    // height: 17px;

    .gha-cube & {
      background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
    }
    .gha-wlhp & {
      background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
    }
    .dunedin & {
      background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
    }
    .loretto & {
      background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
    }
    .barony & {
      background-image: url('{{file_dest}}/barony-bullet-arrow.svg');
    }
    .yourplace & {
      background-image: url('{{file_dest}}/yourplace-bullet-arrow.svg');
    }
    .lowther & {
      background: url('{{file_dest}}/lowther-tick.svg') no-repeat center left;
      background-position: 0 3px;
      background-size: 15px 15px;
      padding-left: 20px;
    }
    .wheatley & {
      background-image: url('{{file_dest}}/wheatley-bullet-arrow.svg');
    }
    &:last-child {
      margin-bottom: 1.5em;
    }
  }
}

.list-check_outside {
  ul & {

  }
  margin: 1.5em 0;
  padding-left: 0px;
  list-style: none;

  li {
    font-weight: 200;
    margin-bottom: 1em;
    background: url('{{file_dest}}/lowther-check.png') no-repeat 0 2px / 21px;
    padding-left: 30px;
    line-height: 1.5;
    // height: 17px;

    .gha-cube & {

    }
    .gha-wlhp & {

    }
    .dunedin & {

    }
    .loretto & {

    }
    .barony & {

    }
    .yourplace & {

    }
    .lowther & {
      background-image: url('{{file_dest}}/lowther-check.png');
      //font-weight: normal;
    }
    &:last-child {
      margin-bottom: 1.5em;
    }
  }
}

.button {
  //background-color: $brand-primary;
  background-color: $brand-cta-yellow;
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  // clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  color: #222;
  cursor: pointer;
  padding: 0.5em calc(2.5em - 10px) 0.5em 2.5em;
  position: relative;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  //-webkit-transform: translate3d(0, 0, 0);

  .gha-cube & {
    background-color: $cube-main-color-red;
    color: #fff !important;
  }

  .gha-wlhp & {
    background-color: $wlhp-main-color-purple;
    color: #fff !important;
  }

  .dunedin & {
    background-color: $dunedin-main-color-yellow;
    color: black !important;
  }
  .loretto & {
    background-color: $loretto-main-color-blue;
    color: #fff !important;
  }
  .barony & {
    background-color: $barony-secondary;
    color: black !important;
  }
  .yourplace & {
    background-color: $yourplace-primary;
    color: #fff !important;
    padding: 0.5em 2.5em 0.5em 2.5em;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .lowther & {
    background-color: $lowther-primary;
    color: #000000 !important;
    padding: 0.5em 2.5em 0.5em 2.5em;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .wheatley & {
    background-color: $wheatley-primary;
    color: white !important;
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    padding: 14px 2.5em 14px 2.5em;
    font-weight: bold;
  }

  &:before,
  &:after {
    box-shadow: -3px 0 0 0 $brand-cta-yellow;
    content: '';
    position: absolute;
    right: -10px;

    .gha-cube & {
      box-shadow: -3px 0 0 0 $cube-main-color-red;
    }
    .gha-wlhp & {
      box-shadow: -3px 0 0 0 $wlhp-main-color-purple;
    }
    .dunedin & {
      box-shadow: -3px 0 0 0 $dunedin-main-color-yellow;
    }
    .loretto & {
      box-shadow: -3px 0 0 0 $loretto-main-color-blue;
    }
    .barony & {
      box-shadow: -3px 0 0 0 $barony-secondary;
    }
    .yourplace & {
      box-shadow: 0 0 0 0 $yourplace-primary;
    }
    .lowther & {
      box-shadow: 0 0 0 0 $lowther-primary;
    }
    .wheatley & {
      box-shadow: 0 0 0 0 $wheatley-primary;
    }
  }

  &:before {
    background: $brand-cta-yellow;
    bottom: 10px;
    top: 0;
    width: 10px;
    border-top-right-radius: 3px;

    .gha-cube & {
      background-color: $cube-main-color-red;
    }
    .gha-wlhp & {
      background-color: $wlhp-main-color-purple;
    }
    .dunedin & {
      background-color: $dunedin-main-color-yellow;
    }
    .loretto & {
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      background-color: $barony-secondary;
    }
    .yourplace & {
      background-color: transparent;
    }
    .lowther & {
      background-color: transparent;
    }
  }

  &:after {
    bottom: 0;
    box-shadow: -4px 0 0 0 $brand-cta-yellow, 0 -4px 0 0 $brand-cta-yellow, -4px -4px 0 0 $brand-cta-yellow;
    border-color: $brand-cta-yellow transparent transparent $brand-cta-yellow;
    border-style: solid;
    border-width: 5px;

    .gha-cube & {
      box-shadow: -4px 0 0 0 $cube-main-color-red, 0 -4px 0 0 $cube-main-color-red, -4px -4px 0 0 $cube-main-color-red;
      border-color: $cube-main-color-red transparent transparent $cube-main-color-red;
    }

    .gha-wlhp & {
      box-shadow: -4px 0 0 0 $wlhp-main-color-purple, 0 -4px 0 0 $wlhp-main-color-purple, -4px -4px 0 0 $wlhp-main-color-purple;
      border-color: $wlhp-main-color-purple transparent transparent $wlhp-main-color-purple;
    }
    .dunedin & {
      box-shadow: -4px 0 0 0 $dunedin-main-color-yellow, 0 -4px 0 0 $dunedin-main-color-yellow, -4px -4px 0 0 $dunedin-main-color-yellow;
      border-color: $dunedin-main-color-yellow transparent transparent $dunedin-main-color-yellow;
    }
    .loretto & {
      box-shadow: -4px 0 0 0 $loretto-main-color-blue, 0 -4px 0 0 $loretto-main-color-blue, -4px -4px 0 0 $loretto-main-color-blue;
      border-color: $loretto-main-color-blue transparent transparent $loretto-main-color-blue;
    }
    .barony & {
      box-shadow: -4px 0 0 0 $barony-secondary, 0 -4px 0 0 $barony-secondary, -4px -4px 0 0 $barony-secondary;
      border-color: $barony-secondary transparent transparent $barony-secondary;
    }
    .yourplace & {
      box-shadow: 0 0 0 0 $yourplace-primary, 0 0 0 0 $yourplace-primary, 0 0 0 0 $yourplace-primary;
      border-color: transparent;
    }
    .lowther & {
      box-shadow: 0 0 0 0 $lowther-primary, 0 0 0 0 $lowther-primary, 0 0 0 0 $lowther-primary;
      border-color: transparent;
    }
    .wheatley & {
      transition-duration: 0.5s;
      box-shadow: 0 0 0 0 $wheatley-primary, 0 0 0 0 $wheatley-primary, 0 0 0 0 $wheatley-primary;
      border-color: transparent;
      position: absolute;
      content: " ";
      width: 30px;
      height: 30px;
      background-color: $wheatley-primary;
      right: -15px;
      bottom: -15px;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
    }
  }

  &:hover {
    background-color: $brand-cta-yellow;
    color: #222;

    .gha-cube & {
      background-color: $cube-main-color-red;
    }
    .gha-wlhp & {
      background-color: $wlhp-main-color-purple;
    }
    .dunedin & {
      background-color: $dunedin-main-color-yellow;
    }
    .loretto & {
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      background-color: $barony-secondary;
    }
    .yourplace & {
      background-color: $yourplace-primary;
    }
    .lowther & {
      background-color: $lowther-primary;
    }
    .wheatley & {
      background-color: $wheatley-primary;
      a {
        text-decoration: underline;
      }
      &:after {
        background-color: $wheatley-yellow;
      }
    }

    .gha-cube &, .gha-wlhp &, .loretto &, .youplace & {
      color: $white !important;
    }
    .dunedin &, .barony &, .lowther & {
      color: $black !important;
    }
  }

  &:focus {
    background: $brand-primary;
    color: $white;

    .gha-cube & {
      background-color: $cube-main-color-red;
    }
    .gha-wlhp & {
      background-color: $wlhp-main-color-purple;
    }
    .dunedin & {
      background-color: $dunedin-main-color-yellow;
    }
    .loretto & {
      background-color: $loretto-main-color-blue;
    }
    .barony & {
      background-color: $barony-secondary;
    }
    .yourplace & {
      background-color: $yourplace-primary;
    }
    .lowther & {
      background-color: $lowther-primary;
    }
    .wheatley & {
      background-color: $wheatley-primary;
      a {
        text-decoration: underline;
      }
      &:after {
        background-color: $wheatley-yellow;
      }
    }
  }
}

.button_border {
  @extend .button;
  border: 2px solid $white;
}

/* Links */
.link-arrow {
  padding-left: 0.875em;
  position: relative;
  display: block;

  &:before {
    content: ">";
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

/* Backgrounds */
.background-digonal-stripes {
  height: 215px;
  width: 1020px;
  background: linear-gradient(
                  -45deg,
                  transparent 80px,
                  transparent 160px,
                  #f2f2f2 160px,
                  #f2f2f2 240px,
                  transparent 240px,
                  transparent 320px,
                  #f2f2f2 320px,
                  #f2f2f2 400px,
                  transparent 400px,
                  transparent 480px,
                  #f2f2f2 480px,
                  #f2f2f2 560px,
                  transparent 560px,
                  transparent 640px,
                  #f2f2f2 640px,
                  #f2f2f2 720px,
                  transparent 720px,
                  transparent 800px
  ), linear-gradient(
                  -135deg,
                  transparent 0,
                  transparent 8px,
                  #eeeeee 8px,
                  #eeeeee 88px,
                  transparent 88px,
                  transparent 168px,
                  #eeeeee 168px,
                  #eeeeee 248px,
                  transparent 248px,
                  transparent 328px,
                  #eeeeee 328px,
                  #eeeeee 408px,
                  transparent 408px,
                  transparent 488px,
                  #eeeeee 488px,
                  #eeeeee 568px,
                  transparent 568px,
                  transparent 648px
  );
  .wheatley & {
    height: 300px;
    background: linear-gradient(
                    -45deg,
                    transparent -85px,
                    transparent -5px,
                    $wheatley-primary-shade2 -5px,
                    $wheatley-primary-shade2 75px,
                    transparent 75px,
                    transparent 135px,
                    $wheatley-primary-shade2 135px,
                    $wheatley-primary-shade2 215px,
                    transparent 215px,
                    transparent 295px
    ), linear-gradient(
                    -135deg,
                    transparent -20px,
                    transparent 60px,
                    $wheatley-primary-shade1 60px,
                    $wheatley-primary-shade1 140px,
                    transparent 140px,
                    transparent 220px
    ), linear-gradient(
                    -45deg,
                    transparent 240px,
                    transparent 275px,
                    $wheatley-primary-shade2 275px,
                    $wheatley-primary-shade2 355px,
                    transparent 355px,
                    transparent 435px
    ), linear-gradient(
                    -135deg,
                    transparent 125px,
                    transparent 205px,
                    $wheatley-primary-shade1 205px,
                    $wheatley-primary-shade1 285px,
                    transparent 285px,
                    transparent 365px
    ), linear-gradient(
                    -45deg,
                    transparent 340px,
                    transparent 420px,
                    $wheatley-primary-shade2 420px,
                    $wheatley-primary-shade2 500px,
                    transparent 500px,
                    transparent 580px
    );
  }

}

.icon-facebook {
  background: url('{{file_dest}}/icon-facebook.png') no-repeat;
  background: url('{{file_dest}}/icon-facebook.svg') no-repeat;
  background-size: contain;
  .wheatley & {
    background: url('{{file_dest}}/wheatley-icon-facebook.svg') no-repeat;
    background-size: contain;
  }
}

.icon-twitter {
  background: url('{{file_dest}}/icon-twitter.png') no-repeat;
  background: url('{{file_dest}}/icon-twitter.svg') no-repeat;
  background-size: contain;
  .wheatley & {
    background: url('{{file_dest}}/wheatley-icon-twitter.svg') no-repeat;
    background-size: contain;
  }
}

.icon-linkedin {
  background: url('{{file_dest}}/icon-linkedin.png') no-repeat;
  background: url('{{file_dest}}/icon-linkedin.svg') no-repeat;
  background-size: contain;
  .wheatley & {
    background: url('{{file_dest}}/wheatley-icon-linkedin.svg') no-repeat;
    background-size: contain;
  }
}

.icon-info {
  background: url('{{file_dest}}/icon-info.png') no-repeat;
  background-size: contain;
}

.icon-arrow-left {
  background: url('{{file_dest}}/icon-arrow-left.png') no-repeat;
  background-size: contain;
}

.icon-arrow-right {
  background: url('{{file_dest}}/icon-arrow-right.png') no-repeat;
  background-size: contain;
}

.clearfix {
  clear: both;
}

.homepage {
  padding-left: 5px !important;
  padding-right: 5px !important;

  @media (max-width: 767px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.homepagetiles {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 8px !important;
  @media (max-width: 768px) {
    padding-bottom: 0 !important;
  }
}

.nogutter {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &:before {
    content: none;
  }

  &:after {
    content: '';
  }
}

.news-listing img {
  border-radius: 2px 2px 0 0;
  margin: 0;
  width: 100%;
}

.news-listing .text {
  background-color: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0 0 2px 2px;
  height: 245px;
}

.news-listing h3 {
  font-size: 1.1em;
  margin-top: 0;
  font-weight: 600;
  line-height: 26px;
  min-height: 52px;
}

.news-listing hr {
  border-top: 1px solid #bfbfbf;
  margin-top: 12px;
  margin-bottom: 5px;
}

.img-wrapper-news {
  position: relative;
  padding-bottom: 49.3%;
  height: 0;
  overflow: hidden;
}

.news-listing.newswide .text {
  background-color: #FCEEED;
}

.news-listing.newswide hr {
  border-top: 1px solid #F6C0B8;
  margin-top: 12px;
  margin-bottom: 5px;
}

select::-ms-expand {
  display: none;
}

.resmarg {
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
}

.sr-only {
  color: $black;
}

table {
  border: 0px;
  margin-bottom: 24px;
  thead {
    tr {
      th {
        background-color: #666666;
        color: $white;
        font-weight: 500;
        font-size: 1em;
        padding: 1em;
        margin: 0;
        border: 1px solid #666666;
        border-right: 1px solid #dfdfdf;
        &:last-child {
          border-right: 1px solid #666666;
        }
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: #666;
        border: 1px solid #666;
        border-right: 1px solid #dfdfdf;
        color: #fff;
        font-size: 1em;
        font-weight: 500;
        margin: 0;
        padding: 1em;
      }
      td {
        color: #666666;
        font-weight: 200;
        background-color: #f8f8f8;
        border: 1px solid #f8f8f8;
        padding: 0.5em 1em;
        border-bottom: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;
        &:last-child {
          border-right: 1px solid #f8f8f8;
        }
      }
      &:last-child {
        td {
          border-bottom: 3px solid #dfdfdf;
        }
      }
    }
  }
}