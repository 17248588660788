/* Module: S */
.landing-page__secondlevel {
  display: block;
  h1 {
    color: $font-grey;
    font-size: pxToEm(24);
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    // These overrides won't be needed when we stop using the gha-cube and gha-wlhp body classes
    .gha-cube &,
    .gha-wlhp &,
    .dunedin &,
    .loretto & ,
    .barony &,
    .yourplace &{
      color: $font-grey;
    }
    .lowther & {
      color: $lowther-font-dark;
      border-bottom: 3px solid $lowther-primary;
    }
.wheatley & {
  color: $wheatley-primary;
  font-size: 34px;
  font-family: Brauer, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 1px dotted $wheatley-secondary;
}
  }

  h2 {
    color: $brand-primary;

    .gha-cube & {
      color: $cube-main-color-green;
    }
    .gha-wlhp & {
      color: $wlhp-main-color-blue;
    }
    .dunedin & {
      color: $dunedin-main-color-blue;
    }
    .loretto & {
      color: $loretto-main-color-blue;
    }
    .barony & {
      color: $barony-dark-blue;
    }
    .yourplace & {
      color: $yourplace-secondary;
    }
    .lowther & {
      color: $lowther-font-grey;
    }
    .wheatley &{
      color: $wheatley-secondary;
      font-size: 30px;
      font-weight: 200;
      line-height: pxToRem(36);
    }

    font-size: pxToEm(24);
    max-width: 800px;
  }

  p {
    max-width: 800px;
  }

  .landing-page-content__bottom {
    display: block;
    &.text {
      padding-right: 15px;
    }
  }

  .landing-page-box, .landing-page-box__wide {
    background: #fdf5f5;
    border-bottom: solid darken(#fdf5f5, 20%) 3px;

    .gha-cube & {
      background: #faf2f5;
      border-bottom: solid darken(#faf2f5, 20%) 3px;
    }
    .gha-wlhp & {
      background: #faf2f5;
      border-bottom: solid darken(#faf2f5, 20%) 3px;
    }
    .dunedin & {
      background: #fffbf0;
      border-bottom: solid #ffc72c 3px;
    }
    .loretto & {
      background: $loretto-light-bg;
      border-bottom: solid $loretto-light-border 3px;
    }
    .barony & {
      background: $barony-bg-landingboxes;
      border-bottom: solid $barony-light-border-landingboxes 3px;
    }
    .yourplace & { //not final
      background: $loretto-light-bg;
      border-bottom: solid $loretto-light-border 3px;
    }
    .lowther & {
      background: $lowther-mobile-nav-secondary;
      border-bottom: solid $lowther-primary 3px;
    }
    .wheatley & {
      background: $wheatley-medium-bg;
      border-bottom: 0px;
      padding: 20px;
      h2 {
        font-size: 26px;
        color: $base-font-colour;
        font-weight: bold;
      }
      .linebottom {
        border-bottom: 1px dotted $wheatley-secondary;
        padding-bottom: 0.6em;
        margin-bottom: 1em;
      }
    }
  }
}

.landing-page-boxes {
  &.landing-page__secondlevel {
    @media (min-width: 768px) {
      margin-right: -17px !important;
    }
  }
}
