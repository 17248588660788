/* Module: S */
.yourplace-services {
  padding: 30px 0px;
  text-align: center;
  h2 {
    font-size: 45px;
    color: $yourplace-secondary;
    margin-bottom: 20px;
  }
  .servicestiles {
    div[class^="col-"] {
      padding: 3px;
      .content {
        position: relative;
        -webkit-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        background-color: $yourplace-primary;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          transform: scale(1.1);
          z-index: 10;
          -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.49);
          -moz-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.49);
          box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.49);
        }
        p {
          color: white;
          max-width: 50%;
          font-size: 29px;
          margin: 0px;
        }
      }
      &:nth-child(odd) {
        .content {
          background-color: $yourplace-primary;
        }
      }
      &:nth-child(even) {
        .content {
          background-color: $yourplace-secondary;
          height: 250px;
        }
      }
    }
  }
}