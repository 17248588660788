/* Module: S */
.localSelectorWrapper {
  z-index: 99;
  position: fixed;
  background-color:
          rgba(0,0,0,0.3);
  top: 10px;
  left: 10px;
  #localselector {
    list-style: none;
    padding: 15px;
    margin: 0px;
    li {
      display: inline-block; padding: 5px; background-color: white;
    }
  }
}