/* Module: S */
.wheatleyredline {
  padding: 30px 0;
  background-color: $wheatley-primary;
  text-align: center;
  h2 {
    color: white;
    font-size: 42px;
    margin: 0px 7% 30px 7%;
  }
}