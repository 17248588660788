/* Module: S */
.footer {
  color: $font-grey;
  font-weight: 200;
  overflow: hidden;
  .wheatley & {
    background: $wheatley-footer-bg;
  }

  h2, h3 {
    color: $brand-primary;

    .gha-cube & {
      color: $cube-main-color-green;
    }
    .gha-wlhp & {
      color: $wlhp-main-color-blue;
    }
    .dunedin & {
      color: $dunedin-main-color-blue;
    }
    .loretto & {
      color: $loretto-main-color-darkblue;
    }
    .barony & {
      color: $barony-primary;
    }
    .yourplace & {
      color: $yourplace-primary;
    }
    .lowther & {
      color: $lowther-links;
    }
    .wheatley & {
      color: $wheatley-secondary;
      border-bottom: 0px !important;
    }
  }
  h3, h4 {
    font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 800;
  }
  h2 {
    margin-top: 40px;
    .wheatley & {
      font-size: 24px;
      font-weight: 500;
    }
  }
  h3 {
    .wheatley & {
      font-weight: 700;
      color: white !important;
    }
  }
  h4 {
    .wheatley & {
      font-weight: 700;
    }
  }

  p {
    .wheatley & {
      color: $wheatley-secondary;
    }
  }

  a {
    color: #000;
    .gha-cube &, .gha-wlhp &, .dunedin &, .loretto &, .barony &, .yourplace & {
      color: #000;
    }
    .wheatley & {
      &.goog-te-menu-value {
        color: $base-font-colour;
        &:hover, &:focus {
          color: $base-font-colour;
          text-decoration: none;
        }
      }
      color: $white;
      font-weight: 200;
      &:hover {
        color: white;
      }
    }
  }

  .background-digonal-stripes {
    position: absolute;
    right: 0;
    bottom: 2px;
  }

  &__top {
    background: #f8f8f8;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 16px;
    .wheatley & {
      background: $wheatley-primary;
    }
  }

  &__bottom {
    font-size: 0.8em;
    padding-right: 15%;
    position: relative;
    padding-bottom: 1em;

    .icon-info {
      height: 25px;
      width: 25px;
      border: none;
    }
    a {
      color: $font-grey;
      .gha-cube &, .gha-wlhp &, .dunedin &, .loretto &, .barony &, .yourplace &, .lowther & {
        color: $font-grey;
      }
    }

  }

  &__headers {
    margin-bottom: 23px;
  }

  &__subheader {
    font-weight: bold;
    margin: 0;
    padding: 0;
    .wheatley & {
      //font-weight: normal;
      color: $wheatley-secondary-brighter;
    }
  }

  &__social-icons {
    display: inline-block;
    height: 23px;
    width: 42px;
  }

  &__links {
    margin: 0;
    overflow: hidden;
    li {
      float: left;
      padding-left: 35px;
      font-weight: bold;
      position: relative;
      .wheatley & {
        color: $wheatley-footer-text;
        font-weight: 700;
      }

      &:before {
        content: ".";
        font-size: 1.6em;
        left: 14px;
        top: 3px;
        line-height: 0px;
        position: absolute;
      }

      &:first-child {
        padding: 0;

        &:before {
          content: "";
        }
      }

      a {
        color: $font-grey;
        .gha-cube &, .gha-wlhp &, .dunedin &, .loretto &, .barony &, .yourplace &, .lowther & {
          color: $font-grey;
        }
        .wheatley & {
          color: $wheatley-footer-text;
          font-weight: 700;
          &:hover, &:focus {
            color: $wheatley-footer-text;
          }
        }
      }
    }
    &.footlogoes {
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      li {
        padding-left: 0px;
        padding-right: 20px;
        align-self: flex-end;
        &:before {
          content: "";
        }
        &:last-child {
          border-left: 2px solid $wheatley-secondary;
          padding-left: 20px;
          padding-right: 0px;
        }
      }
    }
  }

  &__wg-logo {
    display: block;
    height: 40px;
    width: 133px;
    text-align: right;
    position: absolute;
    top: 15px;
    right: 0;
    background: url('{{file_dest}}/wg-grey-logo.png') center /contain no-repeat;
    background: url('{{file_dest}}/wg-grey-logo.svg') center /contain no-repeat;

  }
  &__bottom-info-text {
    p:first-child {
      max-width: 800px;
      color: $wheatley-footer-text;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    .background-digonal-stripes {
      left: -80px;
      .wheatley & {
        left: -40px;
      }
    }
    &__top {
      margin-bottom: 1em;
    }
    &__bottom {
      padding-right: 0;
    }

    &__links li {
      padding: 0;
      float: none;
      margin-bottom: 0.5em;
      &:before {
        content: '';
      }
    }

    &__bottom-info-text {
      display: none;
      padding: 1em 0 2em;

      &.is-active {
        display: block;
      }
    }

    &__wg-logo {
      bottom: 1em;
      top: auto;
      background: url('{{file_dest}}/wg-grey-logo.png') center / auto 30px no-repeat;
      background: url('{{file_dest}}/wg-grey-logo.svg') center / auto 30px no-repeat;
    }
    .footlogoes {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .background-digonal-stripes {
      left: -80px;
      .wheatley & {
        left: -255px;
      }
    }
  }
}