/* Module: S */
.two-boxes {
  margin: 20px 0;

  &-text-wrap {
    color: black;
    padding: 5px 30px;
  }

  &__bg {
    height: 100%;
  }

  &__image {
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__header {
    font-size: 34px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
    margin-right: 40px;

    &:before {
      position: absolute;
      content: "";
      width: 21px;
      height: 30px;
      background-image: url('{{file_dest}}/gray-arrow.svg');
      background-repeat: no-repeat;
      background-size: cover;
      top: 0;
      right: -30px;
    }
  }

  &__content {
    a {
      font-weight: 300;
      color: black;
      text-decoration: underline;
    }
  }
}

.no-margin {
  margin: 0;
}


.lowther .two-boxes__bg {
  background-color: #c4d603;
}