/* Module: S */
// Slick styles have been added to the carousel module
// Slick has been tricked into showing 3 slides when it thinks it's showing 1

.quote-carousel {

  padding: 30px 0;

  &__intro {
    h2, p {
      margin: 0 auto;
      text-align: center;
      width: 50%;
      padding: 20px 0;
      @media (max-width: $screen-xs-max) {
        width: 80%;
      }
    }
    h2 {
      border-bottom: 4px solid $lowther-primary;
      font-size: 31px;
      font-weight: 700;
    }
  }

  @media (max-width: $screen-xs-max) {
    .slick-list {
      padding: 0 10px !important;
    }
  }

  @media (min-width: $screen-sm-min) {
    &__wrapper {
      overflow: hidden;
      position: relative;
      > div {
        position: static;
      }
    }
    &__slide-container {
      position: static;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-track {
      margin: 35px 0;
    }
    .slick-slide {
      margin: 0;
      z-index: 2;
      pointer-events: none;
      .quote-carousel__slide {
        margin: 0 -50%;
        width: 200%;
        opacity: 0;
        transform: scale(0.9);
        transition: transform 0.3s, opacity 0.3s;
      }
      &.slick-center {
        z-index: 4;
        .quote-carousel__slide {
          transform: scale(1.1);
          opacity: 1;
        }
        .quote-carousel__quote {
          border-color: $lowther-primary;
        }
      }
      &.sq-slick-center-neighbour .quote-carousel__slide {
        opacity: 1;
      }
      &.sq-slick-no-transition .quote-carousel__slide {
        transition: none;
      }
      &.sq-slick-hidden .quote-carousel__slide {
        opacity: 0;
      }
    }
  }

  .slick-arrow {
    background: url(mysource_files/quote-carousel-arrow-down.svg) no-repeat;
    font-size: 0;
    border: none;
    color: transparent;
    height: 30px;
    width: 50px;
    border-radius: 0;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    z-index: 10;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.75;
    }
  }
  .slick-prev {
    transform: translate3d(0, -50%, 0) rotateZ(90deg);
  }
  .slick-next {
    transform: translate3d(0, -50%, 0) rotateZ(-90deg);
  }
  .slick-dots li {
      position: relative;
      width: 16px;
      height: 16px;
      &.slick-active button {
        background-color: $lowther-dark-gray;
        opacity: 1;
        width: 16px;
        height: 16px;
        margin: 0px;
        &:hover {
          opacity: 1;
        }
      }
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 12px;
    height: 12px;
    margin: 2px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background-color: $lowther-dark-gray;
    opacity: 0.5;
    border-radius: 12px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.75;
    }
  }
  &__slide {
    background: $white;
    border: 1px solid $lowther-light-gray;
    margin: 20px 0;
    padding: 20px 20px 40px 20px;
    text-align: center;
    @media (max-width: $screen-xs-max) {
      border: 10px solid $lowther-primary;
      position: relative;
      &:before,
      &:after {
        background: $white;
        content: '';
        height: 12px;
        left: 30px;
        position: absolute;
        width: calc(100% - 60px);
      }
      &:before {
        top: -11px;
      }
      &:after {
        bottom: -11px;
      }
    }
    @media (min-width: $screen-sm-min) {
      box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
      width: 150%;
      margin: 0 -25%;
    }
  }
  &__quote {
    @media (min-width: $screen-sm-min) {
      border-width: 20px;
      border-style: solid;
      border-color: $white;
      transition: border-color 0.3s;

      position: relative;
      &:before,
      &:after {
        background: $white;
        content: '';
        height: 22px;
        left: 30px;
        position: absolute;
        width: calc(100% - 60px);
      }
      &:before {
        top: -21px;
      }
      &:after {
        bottom: -21px;
      }

      p {
        padding: 20px;

      }
    }
    @media (min-width: $screen-md-min) {
      min-height: 250px;
      display: flex;
      align-items: center;
    }

    margin: 0 0 20px;
    p {
      margin: 0;
    }
    &-name {
      font-weight: bold;
      margin: 0;
    }
    &-info {
      margin: 0;
    }
  }

  // If there's just one slide, make sure it's centered
  .sq-slick-one-slide .slick-track {
    transform: none !important;
    .quote-carousel__quote {
      min-height: 0px;
    }
  }
  // On medium size screens, make the slides a little wider
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .slick-slide .quote-carousel__slide {
      margin: 0 -150%;
      width: 400%;
    }
  }

}
