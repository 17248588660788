/* Module: S */
.office-content {
  .search-bar {
    margin-bottom: 30px;
    .office__search {
      @extend .intro;
      font-weight: 300;
      font-size: 1.5em;
      padding-right: 60px;
      max-width: 800px;
      .lowther & {
        font-weight: normal;
      }
    }

    .searchresultinput-wapper {
      position: relative;
      width: 46%;
      float: left;
      svg {
        position: absolute;
        right: 2%;
        top: 30px;
        width: 20px;
        .search {
          fill: $brand-primary;
          .gha-cube & {
            fill: $cube-main-color-red;
          }
          .gha-wlhp & {
            fill: $wlhp-main-color-purple;
          }
          .dunedin & {
            fill: $dunedin-main-color-green;
          }
          .loretto & {
            fill: $loretto-main-color-darkblue;
          }
          .barony & {
            fill: $barony-secondary;
          }
          .yourplace & {
            fill: $yourplace-secondary;
          }
          .lowther & {
            fill: $lowther-primary;
          }
          .wheatley & {
            fill: $wheatley-primary;
          }
        }
      }
    }

    .officesearchresultinput {
      float: left;
      height: 38px;
      margin-top: 22px;
      margin-bottom: 16px;
      padding: 0.5em;
      //width: 100%;
      width: 46%;
      font-size: 18px;
      font-weight: 300;
      color: $font-grey;
      border: 1px solid #e4e4e4;
      background: url("mysource_files/search-gha.svg") 98% center / 24px 24px no-repeat;

      @media screen and (max-width: 576px) {
        width: 100%;
      }

      .gha-cube & {
        background: url("mysource_files/search-cube.svg") 98% center / 24px 24px no-repeat;
      }
      .gha-wlhp & {
        background: url("mysource_files/search-wlhp.svg") 98% center / 24px 24px no-repeat;
      }
      .dunedin & {
        background: url("mysource_files/search-dunedin.svg") 98% center / 24px 24px no-repeat;
      }
      .loretto & {
        background: url("mysource_files/search-loretto.svg") 98% center / 24px 24px no-repeat;
      }
      .barony & {
        background: url("mysource_files/search-barony.svg") 98% center / 24px 24px no-repeat;
      }
      .yourplace & {
        background: url("mysource_files/search-yourplace.svg") 98% center / 24px 24px no-repeat;
      }
      .lowther & {
        background: url("mysource_files/search-lowther.svg") 98% center / 24px 24px no-repeat;
        //.lowther & {
        //  font-weight: normal;
        //}
      }
      .wheatley & {
        background: url("mysource_files/search-wheatley.svg") 98% center / 24px 24px no-repeat;
        height: 48px;
      }
    }
    .button__search {
      @extend .button;
      margin-top: 22px;
      float: left;
      margin-left: 10px;
      height: 38px;
      font-weight: 200;
      padding-top: 9px;
      a {
        color: $white;
      }
      @media screen and (max-width: 576px) {
        margin: 0;
      }
      .lowther & {
        font-weight: normal;
      }
    }
    .error {
      color: $brand-primary;

      .gha-cube & {
        color: $cube-main-color-green;
      }
      .gha-wlhp & {
        color: $wlhp-main-color-blue;
      }
      .dunedin & {
        color: $dunedin-main-color-blue;
      }
      .loretto & {
        color: $loretto-main-color-blue;
      }
      .barony & {
        color: $barony-primary;
      }
      .yourplace & {
        color: $yourplace-primary;
      }
      .lowther & {
        color: $lowther-primary;
      }
      .wheatley & {
        color: $wheatley-primary;
      }
    }
  }
  .nearest_container {
    background-color: $white;
    border: 1px solid #e4e4e4;
    .nearest_title {
      h2 {
        background-color: #e4e4e4;
        color: #222222;
        font-size: 1.125em;
        padding: 20px;
        margin: 0;
        cursor: pointer;
        position: relative;
        small {
          font-size: 89%;
          .wheatley & {
            color: $wheatley-secondary-brighter;
          }
        }
        .wheatley & {
          font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
          background-color: $wheatley-primary;
          color: $wheatley-light-bg;
        }
      }
    }
    .nearest_body {
      padding: 10px 3% 30px;
      color: #666666;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
      a:after {
        content: " >";
      }
      .eqheight {
        flex: 1;
        height: 100%;
        overflow: hidden;
        .mapwrapper {
          height: 100%;
        }
        p {
          margin-bottom: 0.4em;
          font-weight: 300;
          //.lowther & {
          //  font-weight: normal;
          //}
          b {
            padding-top: 1em;
            display: block;

          }
        }
        .opening {
          width: 58%;
          clear: both;
          .day {
            float: left;
          }
          .time {
            float: right;

          }
          &__note {
            clear: both;
            width: 100%;
            padding-top: 30px;
            p {
              margin-bottom: 0;
            }
          }
        }
        .map {
          height: 90%;
          min-height: 460px;
          width: 100%;
        }
        .mapfacilities {
          bottom: 0;
          width: 100%;
          height: 10%;
          min-height: 60px;
          background-color: $light-bg;
          border-top: 1px solid $light-border;

          .gha-cube & {
            background-color: $cube-light-bg;
            border-top: 1px solid $cube-light-border;
          }
          .gha-wlhp & {
            background-color: $wlhp-light-bg;
            border-top: 1px solid $wlhp-light-border;
          }
          .dunedin & {
            background-color: $dunedin-light-bg;
            border-top: 1px solid $dunedin-light-border;
          }
          .loretto & {
            background-color: $loretto-light-bg;
            border-top: 1px solid $loretto-light-border;
          }
          .barony & {
            background-color: $barony-bg;
            border-top: 1px solid $barony-border;
          }
          .yourplace & { //not final
            background-color: $loretto-light-bg;
            border-top: 1px solid $loretto-light-border;
          }
          .lowther & { //not final
            background-color: $lowther-mobile-nav-secondary;
            border-top: 1px solid $lowther-primary;
          }
          .wheatley & {
            background-color: $wheatley-light-bg;
            border-top: 1px solid $wheatley-secondary;
          }
          p {
            display: block;
            float: left;
            padding-top: 12px;
            padding-left: 12px;
          }
          .facilities {
            float: right;
            list-style: none;
            display: inline-block;
            padding: 0;
            margin: 0;
            li {
              display: block;
              float: left;
              width: 36px;
              height: 36px;
              margin: 11px 8px 11px 0;
              &.fac_park {
                background: url(mysource_files/fac_park.svg) 50% 50%/36px 36px no-repeat;
                .gha-cube & {
                  background: url(mysource_files/gha-cube-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }

                .gha-wlhp & {
                  background: url(mysource_files/gha-wlhp-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .dunedin & {
                  background: url(mysource_files/dunedin-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .loretto & {
                  background: url(mysource_files/loretto-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .barony & {
                  background: url(mysource_files/barony-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .yourplace & {
                  background: url(mysource_files/yourplace-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .lowther & {
                  background: url(mysource_files/lowther-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
                .wheatley & {
                  background: url(mysource_files/wheatley-fac_park.svg) 50% 50%/36px 36px no-repeat;
                }
              }
              &.fac_wifi {
                background: url(mysource_files/fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                .gha-cube & {
                  background: url(mysource_files/gha-cube-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }

                .gha-wlhp & {
                  background: url(mysource_files/gha-wlhp-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .dunedin & {
                  background: url(mysource_files/dunedin-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .loretto & {
                  background: url(mysource_files/loretto-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .barony & {
                  background: url(mysource_files/barony-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .yourplace & {
                  background: url(mysource_files/yourplace-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .lowther & {
                  background: url(mysource_files/lowther-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
                .wheatley & {
                  background: url(mysource_files/wheatley-fac_wifi.svg) 50% 50%/36px 36px no-repeat;
                }
              }
              &.fac_disab {
                background: url(mysource_files/fac_disab.svg) 50% 50%/36px 36px no-repeat;
                .gha-cube & {
                  background: url(mysource_files/gha-cube-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }

                .gha-wlhp & {
                  background: url(mysource_files/gha-wlhp-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .dunedin & {
                  background: url(mysource_files/dunedin-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .loretto & {
                  background: url(mysource_files/loretto-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .barony & {
                  background: url(mysource_files/barony-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .yourplace & {
                  background: url(mysource_files/yourplace-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .lowther & {
                  background: url(mysource_files/lowther-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
                .wheatley & {
                  background: url(mysource_files/wheatley-fac_disab.svg) 50% 50%/36px 36px no-repeat;
                }
              }
            }
          }
        }
      }

    }
  }
  .other-offices {
    h3 {
      font-weight: 300;
      .lowther & {
        font-weight: normal;
      }
      font-size: 1.5em;
      color: $font-grey;
    }
    ul {
      margin-top: 20px;
      li {
        margin-bottom: 20px;
        a {
          font-weight: 300;
          .lowther & {
            font-weight: normal;
          }
          span {
            color: $font-grey;
          }
        }
      }
    }
  }
  .byarea {
    border-top: 1px solid #ccc;
    font-weight: 300;
    //.lowther & {
    //  font-weight: normal;
    //}
    font-size: 1.5em;
    padding-top: 15px;
    margin: 1em 0;
    color: $font-grey;
    .wheatley & {
      border-top: 1px dotted $wheatley-secondary;
    }
  }
}
