/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

th {
    background: $base-font-colour;
    color: invert($base-font-colour);
}

td {
    border: 1px solid $base-font-colour;
}

ul {
  padding-left: 20px;
}

/* Buttons */
.button {
    @include sq-transition(background-color .3s ease);
    background-color: $base-font-colour;
    color: invert($base-font-colour);
    display: inline-block;
    border: 0;
    padding: .5em 1em;
    &,
    &:visited {
        text-decoration: none;
    }

    &:focus,
    &:hover {
        background-color: darken($base-font-colour, 20%);
    }
}

.button--large {
    @include sq-font-size(1.5);
}

.button--small {
    @include sq-font-size(.8);
}

/* Quotes */
blockquote {
    position: relative;
    padding-left: 3em;
    margin: 2em 0;
    &:before {
        @include sq-font-size(5);
        color: $base-font-colour;
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1em;
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}

.header-404{
  font-size: 1.5em;
  font-weight: 700;
}

/* page intro text */
.page-header-intro-text {
    line-height: 1.3;
    margin-bottom: 0.75em;
    color: $brand-primary;
    max-width: 800px;

    .gha-cube & {
        color: $cube-main-color-green;
    }
    .gha-wlhp & {
        color: $wlhp-main-color-blue;
    }
    .dunedin & {
        color: $dunedin-main-color-blue;
    }
    .loretto & {
        color: $loretto-main-color-blue;
    }
    .barony & {
        color: $barony-primary;
    }
    .wheatley & {
        color: $wheatley-primary;
    }

    font-weight: 300;
    font-size: 1.5em;
    padding-right: 60px;
}
