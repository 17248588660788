/* Module: S */
.lowther-searchfilter {
  background-color: $lowther-primary;
  padding-top: 30px;
  padding-bottom: 15px;

  h2 {
    color: $base-font-colour;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 200;
  }

  .flex_box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &:before {
      width: 0;
    }

    .flex_element {

      //display: -webkit-box;
      //display: -webkit-flex;
      //display: -ms-flexbox;
      //display: flex;
      //-webkit-box-orient: vertical;
      //-webkit-box-direction: normal;
      //-webkit-flex-direction: column;
      //-ms-flex-direction: column;
      //flex-direction: column;
      &.whiteborder {
        border-right: 1px solid white;

        @media (max-width: 767px) {
          border-right: 0;
        }
      }

      padding: 0 15px;
      margin-bottom: 15px;

      &.width15 {
        width: 15%;

        @media (max-width: 991px) {
          width: 50%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &.width20 {
        width: 25%;

        @media (max-width: 991px) {
          width: 50%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      p {
        margin-bottom: 1em;
      }

      select {
        font-weight: 200;
        width: 100%;
        @include sq-border-radius(0px);
        height: 36px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-size: 1.25em;
        color: black;
        padding-left: 5px;
        border: 0px;
        margin: 12px 0;
        padding-right: 35px;
        cursor: pointer;
        margin: 0px;
        background: #ffffff url("mysource_files/dropdown-grey.png") right 10px center / 17px 10px no-repeat;
        background: #ffffff url("mysource_files/lowther_dropdown.svg") right 10px center / 17px 10px no-repeat;

        &:focus::-ms-value {
          background-color: #ffffff;
          color: black;
        }
      }

      &.minmax {
        select {
          width: 41%;

          @media (max-width: 1199px) {
            width: 39%;
          }

          @media (max-width: 991px) {
            width: 43%;
          }

          @media (max-width: 767px) {
            width: 44%;
          }
        }
      }

      #propertymin {
        float: left;
      }

      #propertymax {
        float: right;
      }

      .to {
        float: left;
        width: 8%;
        margin-top: 6px;
        margin-bottom: 0px;
        text-align: center;

        @media (max-width: 1199px) {
          width: 22%;
        }

        @media (max-width: 991px) {
          width: 14%;
        }

        @media (max-width: 767px) {
          width: 12%;
        }
      }

      .button {
        color: white !important;
        background-color: black;

      }
    }
  }
}

.lowther-searchfilter-wrapper {
  .lowther-searchfilter-cointainer {
    padding: 0 40px
  }

  // fix for template not in the home page
  @media (min-width: 991px) {
    .lowther-searchfilter .flex_box .flex_element.width15 {
      width: 50%;
    }

    .lowther-searchfilter .flex_box .flex_element.width20 {
      width: 50%;
    }
  }
}