/* Module: S */
.lowther-featured {
  padding: 60px 0;
  .lowther-howitworks__intro {
    padding: 0 0 30px 0;
  }
  .img-wrapper {
    padding-bottom: 60%;
    .cityname {
      position: absolute;
      width: 100%;
      padding: 3% 0;
      background-color: rgba(0,0,0,0.6);
      bottom: 0;
      p {
        font-size: 21px;
        margin:0;
        color: white;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
.see-all-properties {
  background-color: $lowther-primary;
  padding: 25px 0;
  p{
    text-align: center;
    margin: 0;
    a {
      color: black;
      font-weight: 700;
      position: relative;
      &:hover {
        color: black;
      }
      &:after {
        content: ">";
        font-size: 20px;
        position: absolute;
        bottom: -8px;
        right: -20px;
      }
    }
  }

}