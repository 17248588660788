/* Module: S */
.promo-banner {
  background: #990000 url('mysource_files/cta-ribbon.svg') repeat-y 14% 50%;
  color: #fff;
  padding: 0.75em 5px 8px 5px;
  margin-bottom: 8px;

  .gha-cube & {
    background: $cube-main-color-green url('mysource_files/gha-cube-cta-ribbon.svg') repeat-y 14% 50%;
  }
  .gha-wlhp & {
    background: $wlhp-main-color-blue url('mysource_files/gha-wlhp-cta-ribbon.svg') repeat-y 14% 50%;
  }
  .dunedin & {
    background: $dunedin-main-color-blue url('mysource_files/dunedin-cta-ribbon.svg') repeat-y 14% 50%;
  }
  .loretto & {
    background: $loretto-main-color-blue url('mysource_files/loretto-cta-ribbon.svg') repeat-y 14% 50%;
  }
  .barony & {
    background: $barony-primary url('mysource_files/barony-cta-ribbon.svg') repeat-y 14% 50%;
  }
  .yourplace & {
    background: $yourplace-news-bg url('mysource_files/yourplace-cta-ribbon.svg') repeat-y 14% 50%;
  }

  h2 {
    color: #fff;
    font-size: 1.325em;
    //letter-spacing: 1px;
    margin-bottom: 1.25em;
  }

  .button {
    margin-bottom: 1em;
  }

  a {
    font-weight: lighter;
    &:hover,
    :focus {
      color: $white;
    }
  }
}

@media (max-width: 767px) {
  .promo-banner {
    background-position: 8% 50%;
  }
}
