/* Module: S */
.lowther-tab {
  background-color: $lowther-bg-light-gray;
  padding: 30px 0;

  .nav-tabs {
    border-bottom: 0px;
    text-align: center;
    > li {
      float: none;
      display: inline-block;
      zoom: 1;
      > a {
        padding: 10px 25px;
        @media (max-width: 767px) {
          padding: 10px 5px;
        }
        color: $lowther-dark-gray;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 2px solid transparent;
        border-radius: 0px;
        &:hover {
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-bottom: 2px solid $lowther-primary;
          background-color: transparent;
        }
      }
      &.active {
        > a {
          color: $lowther-dark-gray;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-bottom: 2px solid $lowther-primary;
          background-color: transparent;
        }
      }
    }
  }

  .tab-content {
    padding: 30px 0;
    .location-images, .floor-plan {
      position: relative;
      .slick-next, .slick-prev {
        width: 21px;
        height: 38px;
        padding: 0;
        margin-top: 0px;
        border: 0px;
      }
      .slick-prev {
        left: -5%;
        @media (max-width: 768px) {
          left: 15px;
        }
        background: url(mysource_files/lowther-icon-arrow-left.svg) center center / cover;
      }
      .slick-next {
        right: -5%;
        @media (max-width: 768px) {
          right: 15px;
        }
        background: url(mysource_files/lowther-icon-arrow-right.svg) center center / cover;
      }
      .slick-dots {
        li {
          button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 12px;
            height: 12px;
            margin: 2px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background-color: #58595b;
            opacity: 0.5;
            border-radius: 12px;
            transition: opacity 0.2s;
          }
          &.slick-active {
            button {
              background-color: #58595b;
              opacity: 1;
              width: 16px;
              height: 16px;
              margin: 0px;
            }
          }
        }

      }
      img {
        width: 100%;
        height: auto;
        margin: 0;
      }
    }
    .homevideo-widget {
      position: relative;
      background-color: #000;
      padding-bottom: 56.25%;
      @media (max-width: 768px) {
        padding-bottom: 58%;
      }
      .homevideo-overlay {
        z-index: 1;
        .homevideo-text {
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          svg {
            cursor: pointer;
            @media (max-width: 768px) {
              margin-top: 15px;
              width: 50px;
              height: 50px;
            }
          }
        }
      }

      .homevideo-thumbnail {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
    #gmap {
      width: 100%;
      height: 550px;
    }
  }
}