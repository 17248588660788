/* Module: S */

.wheatley_news_n_blogs {
  margin: 20px 0 0 0;
  position: relative;
  h2 {
    padding-bottom: 10px;
    font-size: 34px;
    font-weight: 700;
    color: $wheatley-primary;
    border-bottom: 1px dotted $wheatley-secondary;
    a {
      color: $wheatley-secondary;
      font-size: 16px;
      font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      @media (max-width: 1200px) {
        display: block;
      }
    }
  }
  .main-col {
    &:first-child {
      padding-right: 10px;
      @media (max-width: 991px) {
        padding-right: 15px;
      }
    }
    &:last-child {
      padding-left: 10px;
      @media (max-width: 991px) {
        padding-left: 15px;
      }
    }
  }
  .sub-col {
    padding-left: 10px;
    padding-right: 10px;
    &:first-child {
      padding-left: 15px;
      @media (max-width: 991px) {
        padding-right: 15px;
      }
    }
    &:last-child {
      padding-right: 15px;
      @media (max-width: 991px) {
        padding-left: 15px;
      }
    }
    .img-wrapper {
      position: relative;
      padding-bottom: 230px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
      .to-wrap {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
    .news-wrapper {
      background-color: $wheatley-light-bg;
      padding: 15px;
      margin-bottom: 15px;
      .titlewrapper {
        //height: 80px;
        overflow: hidden;
        margin-bottom: 5px;
        border-bottom: 1px dotted $wheatley-secondary;
        h4 {
          font-size: 18px;
          font-weight: 700;
          font-family: $font-family-sans-serif;
          margin-bottom: 20px;
          a {
            span {
              font-weight: 700;
              line-height: 26px;
            }
          }
        }
      }
      p {
        margin-bottom: 10px;
        a {
          font-weight: 200;
        }
      }
    }
  }
}