/* Module: S */
/*Right hand column*/

.aside-header {
  border-bottom: 1px solid #ccc;
  color: $font-grey;
  padding-bottom: 10px;
  font-size: 1.5em;
  font-weight: 700;
  margin-top:16px;
  .lowther & {
    color: $base-font-colour;
    border-bottom: 4px solid #c4d600;
  }
  .wheatley & {
    color: $wheatley-primary;
    border-bottom: 1px dotted $wheatley-secondary;
    font-size: pxToEm(34);
    margin-top: 0.67em;
  }
}
.latest-news {
  h3 {
    font-size: 1.1em;
    margin-top: 0;
    font-weight: 600;
    line-height: 26px;
    .gha-wlhp & a{
      color: $wlhp-main-color-purple !important;
    }
    .dunedin & a{
      color: #002E6B !important;
    }
    .loretto & a{
      color: $loretto-main-color-darkblue !important;
    }
    .yourplace & a{
      color: $yourplace-secondary !important;
    }
    .wheatley & {
      font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 18px;
    }
  }
  p {
    font-weight: 200;
    margin-bottom: 0;
  }
  .text {
    background-color: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 0 0 2px 2px;
    hr {
      .wheatley & {
        border:none;
        border-top:1px dotted $wheatley-secondary;
        height:1px;
      }
    }
  }

  .img {
    @media (max-width: 991px) {
      position: relative;
      padding-bottom: 230px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
    }

    img {
      @media (max-width: 991px) {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }

  img {
    border-radius: 2px 2px 0 0;
    margin: 0;
  }
  hr {
    border-top: 1px solid #bfbfbf;
    margin-top: 12px;
    margin-bottom: 5px;
  }
}
