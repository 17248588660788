/* Module: S */
.yourplace-services-flip {
  padding: 30px 0px;
  text-align: center;
  h2 {
    font-size: 45px;
    color: $yourplace-secondary;
    margin-bottom: 34px;
  }
  .servicestiles {
    .flip-container {
      -webkit-perspective: 1000;
      -moz-perspective: 1000;
      -ms-perspective: 1000;
      perspective: 1000;
      -ms-transform: perspective(1000px);
      -moz-transform: perspective(1000px);
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      padding: 3px;
      height: 200px;
      &:nth-child(odd) {
        .flipper {
          .front {
            background-color: $yourplace-primary;
          }
          .back {
            background-color: grey;
          }
        }
      }
      &:nth-child(even) {
        .flipper {
          .front {
            background-color: $yourplace-secondary;
          }
          .back {
            background-color: grey;
          }
        }
      }
    }
    .flip-container:hover .back {
      transform: rotateY(0deg);
    }
    .flip-container:hover .front {
      transform: rotateY(180deg);
    }
    .flipper {
      transition: 1.1s;
      transform-style: preserve-3d;
      -webkit-transition: 1.1s;
      -webkit-transform-style: preserve-3d;
      -ms-transition: 1.1s;
      -moz-transition: 1.1s;
      -moz-transform: perspective(1000px);
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      position: relative;
    }

    /* hide back of pane during swap */
    .front, .back {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: 1.1s;
      transform-style: preserve-3d;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 194px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: white;
        font-size: 26px;
        max-width: 50%;
        margin: 0;
        font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      a, a:hover {
        color: white;
      }
    }
    .front {
      z-index: 2;
      transform: rotateY(0deg);
    }
    .back {
      transform: rotateY(-180deg);
    }
    .vertical.flip-container {
      position: relative;
    }

    .vertical .back {
      transform: rotateX(180deg);
    }

    .vertical.flip-container:hover .back {
      transform: rotateX(0deg);
    }

    .vertical.flip-container:hover .front {
      transform: rotateX(180deg);
    }
  }
}