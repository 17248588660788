/* Module: S */
.lowther-icons {
  &__item {
    figure {
      padding-top: 50px;
      width: 100%;
      text-align: center;
    }

    svg {
      width: 95px;
    }

    figcaption {
      font-size: 28px;
      text-align: center;
      padding-top: 20px;
    }

    p {
      color: #222;
      font-family: "FSMe", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}

.lowther .lowther-icons__item svg {
  fill: #c4d600;
}