/* Module: S */
.yourplace-change {
  padding: 30px 0px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  h2 {
    font-size: 45px;
    color: $yourplace-secondary;
  }
  .line_bottom {
    border-bottom: 1px solid $yourplace-secondary;
    padding-bottom: 100px;
    @media (max-width: 768px) {
      border-bottom: 0px
    }
  }
  .changesnum {
    a {
      figure {
        padding-bottom: 0;
        margin-top: -65.5px;
        @media (max-width: 768px) {
          margin-top: 0px
        }
        width: 100%;
        svg {
          width: 125px;
          position: relative;
        }
        figcaption {
          h3 {
            font-size: 28px;
            color: $yourplace-secondary;
            margin-bottom: 27px;
          }
          p {
            color: $base-font-colour;
          }
          font-size: 16px;
          text-align: center;
          padding: 20px 0px;
          font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
      }
      &:hover {
        text-decoration: none;
        figure {
          figcaption {
            h3 {
              text-decoration: underline;
            }
          }
        }
      }
    }

  }
}