/* Module: S */
.lowther-howitworks {
  &__intro {
    padding: 60px 0 30px 0;
    h2, p {
      margin: 0 auto;
      text-align: center;
      width: 50%;
      padding: 20px 0;
      @media (max-width: 767px) {
        width: 80%;
      }
    }
    h2 {
      border-bottom: 4px solid $lowther-primary;
      font-size: 31px;
      font-weight: 700;
    }
  }
}

.propertieslist {
  @media (max-width: 767px) {
    padding:30px 0 50px;
  }
  position: relative;
  &:after {
    position: absolute;
    content: " ";
    background-color: $lowther-primary;
    bottom: 0;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
    @media (max-width: 991px) {
      content: none;
    }
  }
  &:nth-child(even) {
    background-color: $lowther-bg-light-gray;
  }
  &:nth-child(odd) {
    background-color: white;
  }
  &.first {
    &:after {
      height: 80%;
    }
  }
  padding: 60px 0;
  .img-wrapper {
    margin-top: 20px;
    padding-bottom: 60%;
    @media (max-width: 767px) {
      display:none;
    }
  }
  h3 {
    font-size: 22px;
    margin-bottom: 24px;
  }
  .listnumber {
    margin-top: -10px;
    font-size: 31px;
    font-weight: 700;
    text-align: center;
    color: white;
    position: relative;
    z-index: 1;
    @media (max-width: 991px) {
      display: none;
    }
    &:before {
      position: absolute;
      content: " ";
      background-color: $lowther-primary;
      top: calc(50% - 45px);
      left: calc(50% - 45px);
      width: 90px;
      height: 90px;
      z-index: -1;
      @include sq-border-radius(45px);
      @media (max-width: 991px) {
        content: none;
      }
    }
    &:after {
      position: absolute;
      content: " ";
      border: 4px solid white;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      width: 80px;
      height: 80px;
      z-index: 1;
      @include sq-border-radius(40px);
      @media (max-width: 991px) {
        content: none;
      }
    }
  }
}
