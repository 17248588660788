/* Module: S */
.search-bar {
margin-bottom: 30px;
  .main-header__search {
    @extend .main-header;
    //font-weight: 300;
  }
  .intro__search {
    @extend .intro;
    font-weight: 300;
    font-size: 1.5em;
    padding-right: 35%;
    .lowther & {
      font-weight: normal;
    }
  }
  .searchresultinput-wapper {
    position: relative;
    width: 40%;
    svg {
      position: absolute;
      right: 2%;
      top: 35px;
      width: 20px;
      .search{
        fill: $brand-primary;
        .gha-cube & {
          fill: $cube-main-color-red;
        }
        .gha-wlhp & {
          fill: $wlhp-main-color-purple;
        }
        .dunedin & {
          fill: $dunedin-main-color-green;
        }
        .loretto & {
          fill: $loretto-main-color-darkblue;
        }
        .barony & {
          fill: $barony-secondary;
        }
        .yourplace & {
          fill: $yourplace-secondary;
        }
        .lowther & {
          fill: $lowther-primary;
        }
        .wheatley & {
          fill: $wheatley-primary;
        }
      }
    }
  }

  .searchresultinput {
    float: left;
    height: 38px;
    margin-top: 25px;
    padding: 0.5em;
    //width: 100%;
    width: 40%;
    font-size: 18px;
    font-weight: 300;
    color: $font-grey;
    border: 1px solid #e4e4e4;
    background: url("mysource_files/search-gha.svg") 98% center / 24px 24px no-repeat;

    @media (max-width: 767px) {
      width: 100%;
    }

    .gha-cube & {
      background: url("mysource_files/search-cube.svg") 98% center / 24px 24px no-repeat;
    }
    .gha-wlhp & {
      background: url("mysource_files/search-wlhp.svg") 98% center / 24px 24px no-repeat;
    }
    .dunedin & {
      background: url("mysource_files/search-dunedin.svg") 98% center / 24px 24px no-repeat;
    }
    .loretto & {
      background: url("mysource_files/search-loretto.svg") 98% center / 24px 24px no-repeat;
    }
    .barony & {
      background: url("mysource_files/search-barony.svg") 98% center / 24px 24px no-repeat;
    }
    .yourplace & {
      background: url("mysource_files/search-yourplace.svg") 98% center / 24px 24px no-repeat;
    }
    .lowther & {
      background: url("mysource_files/search-lowther.svg") 98% center / 24px 24px no-repeat;
    }
    .wheatley & {
      background: url("mysource_files/search-wheatley.svg") 98% center / 24px 24px no-repeat;
      height: 48px;
    }
  }
  .button__search {
    @extend .button;
    margin-top: 25px;
    float: left;
    margin-left: 10px;
    height: 38px;
    font-weight: 200;
    padding-top: 9px;
    a {
      color: $white;
    }
    .wheatley & {
      height: 48px;
    }
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
}
