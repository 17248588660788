/* Module: S */
.red-arrows {
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  max-height: 200px;
  @include sq-border-radius(3px);

  .gha-cube & {
    background: $cube-main-color-green;
  }
  .gha-wlhp & {
    background: $wlhp-main-color-blue;
  }
  .dunedin & {
    background: $dunedin-main-color-blue;
  }
  .loretto & {
    background: $loretto-main-color-darkblue;
  }
  .barony & {
    // background: $barony-primary;
    background: $barony-bg-gray;
  }
  .yourplace & {
    background: $yourplace-secondary;
  }
  img {
    margin: 0;
    float: right;
  }
  .arrowtip {
    position: absolute;
    width: 57%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    .acontainer {
      position: absolute;
      top: 50%;
      right: 336px;
      -ms-transform: rotate(45deg) translateY(-70%);
      -webkit-transform: rotate(45deg) translateY(-70%);
      transform: rotate(45deg) translateY(-70%);
      .amiddle {
        background: #E12E14;
        height: 500px;
        width: 500px;

        .gha-cube & {
          background: $cube-main-color-green;
        }
        .gha-wlhp & {
          background: $wlhp-main-color-blue;
        }
        .dunedin & {
          background: #002e6b;
        }
        .loretto & {
          background: $loretto-main-color-darkblue;
        }
        .barony & {
          background: $barony-bg-gray;
        }
        .yourplace & {
          background: $yourplace-secondary;
        }

        &:before {
          content: " ";
          display: block;
          height: 22px;
          width: 100%;
          //background-color: #de2e14;
          background-color: #FFC72C;

          .gha-cube & {
            background-color: #40a498;
          }
          .gha-wlhp & {
            background-color: #4079b1;
          }
          .dunedin & {
            background-color: #FFC72C;
          }
          .loretto & {
            background-color: $loretto-main-color-blue;
          }
          .barony & {
            background-color: $barony-secondary;
          }
          .yourplace & {
            background: $yourplace-primary;
          }
        }
        &:after {
          position: absolute;
          content: " ";
          display: block;
          height: 100%;
          width: 22px;
          //background-color: #E5624F;
          background-color: #FFE9AB;

          .gha-cube & {
            background-color: #7fc2ba;
          }
          .gha-wlhp & {
            background-color: #7fa5cb;
          }
          .dunedin & {
            background-color: #ffe395;
          }
          .loretto & {
            background-color: $loretto-main-color-blue-lightest;
          }
          .barony & {
            background-color: $barony-light-border;
          }
          .yourplace & {
            background: $yourplace-news-bg;
          }
          right: 0;
        }
      }
    }
  }
  .arrows__caption {
    position: absolute;
    top: 15px;
    left: 13px;
    a:hover{
      color: $white;
    }
    h2 {

      margin-top: 5px;
      color: $white;
      font-size: 1.5em;
      font-weight: 200;
      margin-bottom: 25px;
    }
    p {

      color: $white;
      font-weight: 200;
      margin-bottom: 10px;
    }
    .red-arrows_social {
      @extend %list-reset;
      display: inline-block;
      padding-left: 0px;
      padding-bottom: 0px;
      li {
        float: left;
        margin: 0;
        .teamicon {
          display: block;
          width: 36px;
          height: 36px;
          margin: 0px 5px 0px 0px;
          &.facebook {
            background: url(mysource_files/red_arrow_facebook.png) 50% 50%/36px 36px no-repeat;
            background: url(mysource_files/red_arrow_facebook.svg) 50% 50%/36px 36px no-repeat;
          }
          &.twitter {
            background: url(mysource_files/red_arrow_twitter.png) 50% 50%/36px 36px no-repeat;
            background: url(mysource_files/red_arrow_twitter.svg) 50% 50%/36px 36px no-repeat;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    //background: $dark-red;
    background: #E12E14;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;


    .arrows__caption h2 {
      width: 100%;
    }

    .arrows__caption {
      position: relative;
      transform: none;
      top: 11px;
      left: 0;
      padding: 8px;
    }

    .arrowtip {
      left: auto;
      right: 0;

      .acontainer {
        right: 308px;

        .amiddle {

          &:before {
            content: " ";
            display: block;
            height: 70px;
            width: 100%;
            background-color: #fff;
            //border-bottom: 16px solid #de2e14;
            border-bottom: 16px solid #FFC72C;
            .gha-cube & {
              background-color: #fff;
              border-bottom: 16px solid #40a498;
            }
            .gha-wlhp & {
              background-color: #fff;
              border-bottom: 16px solid #4079b1;
            }
            .dunedin & {
              background-color: #fff;
              border-bottom: 16px solid #FFC72C;
            }
            .loretto & {
              background-color: #fff;
              border-bottom: 16px solid $loretto-main-color-blue;
            }
            .barony & {
              background-color: #fff;
              border-bottom: 16px solid $barony-secondary;
            }
            .yourplace & {
              background-color: #fff;
              border-bottom: 16px solid $yourplace-primary;
            }
          }

          &:after {
            display: none;
          }
        }
      }
    }



    p,
    img,
    .arrows__caption .red-arrows_social {
      display: none;
    }
  }
}
