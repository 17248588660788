/* Module: S */
.yourplace-bluestripe2{
  background-color: $yourplace-secondary;
  padding: 20px 0;
  div[class^="col-md"] {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 20px;
      padding-right: 55px;
      color: white;
      margin-bottom: 0;
      font-weight: 700;
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}
.yourplace-bluestripe3 {
  background-color: $yourplace-primary;
  padding: 20px 0;
  div[class^="col-md"] {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 30px;
      color: white;
      margin-bottom: 0;
      font-weight: 700;
      line-height: 40px;
      padding-right: 55px;
      &.percent {
        font-size: 132px;
        font-weight: 700;
      }
      &.textpercent {
        color: $yourplace-secondary;
      }
    }
  }
}