/* Module: S */
.jobs {
  .wheatleylibrary {
    background: none;
    padding: 0;
    .container {
      padding: 0;
    }
    .whitebg {
      margin-bottom: 25px;
      min-height: 280px;
    }
    .libcard, .libcarddownload {
      svg {
        float: right;
      }
      .flexdoc {
        display: block;
      }
      p {
        font-size: 16px;
        line-height: 1em;
        margin-bottom: 10px;
      }
      &:before {
        right: -25px;
        background: #F4F2F0;
        @media (max-width: 767px) {
          width: 1000px;
          height: 1000px;
          left: auto;
          right: 60px;
        }
      }
      &:after {
        background: none;
      }
    }
    .libcarddownload {
      h3 {
        margin-bottom: 2em;
      }
    }
    .featured {
      .libcard, .libcarddownload {
        svg {
          float: right;
        }
        .flexdoc {
          display: block;
        }
        &:before {
          background: #E9E3E4;
          @media (max-width: 767px) {
            width: 1000px;
            height: 1000px;
            left: auto;
            right: 60px;
          }
        }
        &:after {
        }
      }
    }
  }
  .more {
    margin-top: 10px;
  }
}