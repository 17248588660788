// typography
//loretto care
// Bold
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Bold.eot');
  src: url('{{file_dest}}/FSMeWeb-Bold.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// BoldItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-BoldItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-BoldItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

// Heavy
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Heavy.eot');
  src: url('{{file_dest}}/FSMeWeb-Heavy.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

// HeavyItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-HeavyItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-HeavyItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-HeavyItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

// Italic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Italic.eot');
  src: url('{{file_dest}}/FSMeWeb-Italic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

// Light
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Light.eot');
  src: url('{{file_dest}}/FSMeWeb-Light.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

// LightItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-LightItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-LightItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

// Regular
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Regular.eot');
  src: url('{{file_dest}}/FSMeWeb-Regular.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

//Your Place
@font-face {
  font-family: 'CoreRhino';
  src: url('{{file_dest}}/CoreRhino35-webfont.eot');
  src: url('{{file_dest}}/CoreRhino35-webfont.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/CoreRhino35-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'CoreRhino';
  src: url('{{file_dest}}/CoreRhino65-webfont.eot');
  src: url('{{file_dest}}/CoreRhino65-webfont.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/CoreRhino65-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-regular.eot');
  src: url('{{file_dest}}/lineto-brauerneue-regular.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-italic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-italic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-bolditalic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-bolditalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-bolditalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-bold.eot');
  src: url('{{file_dest}}/lineto-brauerneue-bold.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-black.eot');
  src: url('{{file_dest}}/lineto-brauerneue-black.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-blackItalic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-blackItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-blackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

h1 {
  font-size: 1.5em;
  font-weight: 700;
  //letter-spacing: 1px;
}

h2 {
  font-size: 1.125em;
}

h3, h4 {
  font-size: 1em;
}

h1, h2, h3, h4 {
  .yourplace & {
    font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .wheatley & {
    font-family: Brauer, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

p {
  line-height: 1.5;
  margin-bottom: 1.5em;
  font-weight: 200;
  //.lowther & {
  //  font-weight: normal;
  //}
}

.header-404 {
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1.3;

  @media screen and (max-width: 768px) {
    font-size: 1.6em;
    line-height: 1.4;
  }
}
