/* Module: S */
.search-content {
  .main-header__searchside {
    @extend .main-header;
    font-weight: 700;
    font-size: 1.5em;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  ul.filters {
    @extend %list-reset;
    li {
      font-size: 16px;
      font-weight: 300;
      color: $brand-primary;
      padding-top: 15px;

      .gha-cube & {
        color: $cube-main-color-green;
      }
      .gha-wlhp & {
        color: $wlhp-main-color-blue;
      }
      .dunedin & {
        color: $dunedin-main-color-blue;
      }
      .loretto & {
        color: $loretto-main-color-blue;
      }
      .barony & {
        color: $barony-primary;
      }
      .yourplace & {
        color: $yourplace-primary;
      }
      .lowther & {
        color: $lowther-primary;
      }
      .wheatley & {
        color: $wheatley-primary;
      }
    }
  }
}
