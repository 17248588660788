/* Module: S */
.yourplace-top {
  margin-top: -1.32em;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 420px;
  width: 100%;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  &__content {
    padding: 35px 75px 70px 74px;
    margin-left: 100px;
    position: absolute;
    top: 20%;
    color: $white;
    max-width: 640px;
    background: url('mysource_files/yourplace-bubble-intro.svg') 50% 50% no-repeat;
    @media (max-width: 1200px) {
      margin-left: 15px;
    }
    @media (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
      padding: 15px;
      background: $yourplace-primary;
    }
    h1 {
      margin-top:0;
      font-size: 42px;
      margin-bottom: 18px;
      font-weight: 700;
    }
    p, a {
      font-size: 16px;
      margin-bottom: 18px;
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    a {
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        color: white;
      }
    }
  }
  &__content2 {
    padding: 35px 75px 70px 74px;
    margin-left: 100px;
    position: absolute;
    top: 20%;
    color: $white;
    max-width: 640px;
    background: url('mysource_files/yourplace-bubble_reveresed.svg') 50% 50% no-repeat;
    @media (max-width: 1200px) {
      margin-left: 15px;
    }
    @media (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
      padding: 15px;
      background: $yourplace-primary;
    }
    h1 {
      margin-top:0;
      font-size: 42px;
      margin-bottom: 18px;
      font-weight: 700;
    }
    p, a {
      font-size: 16px;
      margin-bottom: 18px;
      font-family: CoreRhino, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    a {
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        color: white;
      }
    }
  }
}