/* Module: S */
.wheatleyctas {
  margin-top: -105px;
  @media (max-width: 767px) {
    margin-top: 0;
  }
  padding-bottom: 95px;
  .gutterten {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 5px;
    @media (max-width: 767px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    &:first-child {
      padding-left: 15px !important;
      @media (max-width: 767px) {
        padding-left: 0px !important;
      }
    }
    &:last-child {
      padding-right: 15px !important;
      @media (max-width: 767px) {
        padding-right: 0px !important;
      }
    }
    a {
      .wheatleyctared, .wheatleyctagrey, .wheatleyctayellow {
        width: 100%;
        padding-bottom: 215px;
        @media (max-width: 767px) {
          padding-bottom: 130px;
        }
        overflow: hidden;
        height: 0;
        position: relative;
        &:before {
          position: absolute;
          width: 400px;
          height: 300px;
          transform: rotate(-45deg);
          content: " ";
          top: -118px;
          left: -176px;
          @media (max-width: 991px) {
            left: -190px;
          }
          @media (max-width: 767px) {
            left: auto;
            width: 1000px;
            height: 1000px;
            left: auto;
            top: -500px;
            right: 207px;
          }
          z-index: 2;
        }
        &:after {
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0,0,0,0);
          transition-duration: 0.5s;
          z-index: 1;
        }
        .to-wrap-desk {
          left: 0;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          z-index: 1;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .to-wrap-mobi {
          display: none;
          @media (max-width: 767px) {
            display: block;
            right: 0;
            max-width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
          }
        }
        .ctacontent {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          width: 100%;
          padding: 15px;
          h3 {
            margin-top: 0;
            color: white;
            font-size: 28px;
            max-width: 63%;
            font-weight: normal;
            @media (max-width: 991px) {
              font-size: 23px;
              max-width: 90%;
            }
            @media (max-width: 767px) {
              max-width: 180px;
              font-size: 28px;
              max-width: 70%;
            }
          }
        }
      }

      .wheatleyctared {
        &:before {
          background-color: $wheatley-primary;
          //opacity: 0.1;
        }
        .ctacontent {
          p {
            color: $wheatley-secondary;
          }
        }
      }

      .wheatleyctagrey {
        &:before {
          background-color: $wheatley-secondary;
          //opacity: 0.1;
        }
        .ctacontent {
          p {
            color: $wheatley-primary;
          }
        }
      }

      .wheatleyctayellow {
        &:before {
          background-color: $wheatley-yellow;
          //opacity: 0.1;
        }
        .ctacontent {
          p {
            color: $wheatley-primary;
          }
        }
      }
      &:hover, &:focus {
        .wheatleyctared, .wheatleyctagrey, .wheatleyctayellow {
          &:after {
            background-color: rgba(0,0,0,0.2);
          }
          .ctacontent {
            p {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}